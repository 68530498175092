import {
  CommentListRes,
  Forum,
  ForumCommentReq,
  ForumPostReq,
  ForumPostRes,
} from "_interfaces/forum.interface";
import { Api } from "services/api";

export const forumApi = Api.injectEndpoints({
  endpoints: (build) => ({
    ForumPostList: build.query<ForumPostRes, ForumPostReq>({
      query: (params) => {
        return {
          url: `forum/forum/user`,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),
    ForumDetail: build.query<{ data: Forum }, { id: string }>({
      query: (param) => `forum/forum/user/${param.id}`,
      keepUnusedDataFor: 0,
    }),
    ForumCommentList: build.query<CommentListRes, ForumCommentReq>({
      query: (param) => {
        return {
          url: `forum/comment/member/${param.id}`,
          params: {
            page: param.page,
            limit: param.limit,
            search: param.search,
          },
        };
      },
      keepUnusedDataFor: 0,
    }),
    postComment: build.mutation<
      { success: boolean },
      { text: string; forumId: string }
    >({
      query(body) {
        return {
          url: `/forum/comment/member`,
          method: "POST",
          body,
        };
      },
    }),
    likeForum: build.mutation<{ message: string }, { forumId: string }>({
      query(body) {
        return {
          url: `/forum/likes`,
          method: "POST",
          body,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useForumPostListQuery,
  useForumDetailQuery,
  useForumCommentListQuery,
  usePostCommentMutation,
  useLikeForumMutation,
} = forumApi;
