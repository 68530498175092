import { useEffect, useState } from "react";

const useImgProfilePreview = (fileList?: FileList) => {
 const [imgSrc, setImgSrc] = useState<string>();

  useEffect(() => {
    if (fileList && fileList[0]) {
      const newUrl = URL.createObjectURL(fileList[0]);
      setImgSrc(newUrl);

      // Cleanup function to revoke the object URL
      return () => {
        URL.revokeObjectURL(newUrl);
      };
    } else {
      setImgSrc(undefined);
    }
  }, [fileList]);

  return imgSrc;
};

export default useImgProfilePreview;
