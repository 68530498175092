import { Avatar, Card } from "@mui/material";
import { Forum } from "_interfaces/forum.interface";
import {
  IoChatbubbleEllipsesOutline,
  IoHeart,
  IoHeartOutline,
} from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
interface props {
  data: Forum;
  navigateToDetail?: () => void;
  like: () => Promise<void>;
}
const ForumCard: React.FC<props> = ({ data, navigateToDetail, like }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Card className={`flex mb-6 p-5 w-full border-none shadow-none rounded-md`}>
      <div className="hidden lg:flex w-full">
        <div className="w-[250px] max-h-[170px] object-cover box-border">
          <img
            src={data?.image}
            alt="image"
            className={`object-center w-full h-full rounded-xl border-2 border-black p-1 ${
              navigateToDetail ? "cursor-pointer" : ""
            }`}
            onClick={() => {
              if (navigateToDetail) {
                navigateToDetail();
              }
            }}
          />
        </div>
        <div className="flex flex-col w-full pl-5 relative">
          <div className="flex flex-col gap-2">
            <p
              className={`text-lg text-[#262626] ${
                navigateToDetail ? "cursor-pointer" : "cursor-default"
              }`}
              style={{ fontWeight: 700 }}
              onClick={() => {
                if (navigateToDetail) {
                  navigateToDetail();
                }
              }}
            >
              {data?.title}
            </p>
            <p className="text-sm text-[#92929D] font-normal">
              {data?.description !== undefined &&
                (data.description.length > 255
                  ? data?.description.slice(0, 250) + "..."
                  : data?.description)}
            </p>
          </div>
          <div className="flex justify-between w-full items-center absolute bottom-0 pr-8">
            <div className="flex gap-4 items-center">
              <Avatar
                src="https://docs.material-tailwind.com/img/face-2.jpg"
                alt="avatar"
                variant="circular"
              />
              <div className="flex flex-col">
                <p className="text-lg font-semibold text-black">Moderator</p>
                <p className="text-sm font-medium">{data?.moderator}</p>
              </div>
            </div>
            <div className="flex ml-2 items-center gap-4 relative z-10">
              <div className="flex gap-2 items-center">
                {data?.isLiked ? (
                  <IoHeart
                    size={20}
                    color="#F65858"
                    onClick={like}
                    className="cursor-pointer"
                  />
                ) : (
                  <IoHeartOutline
                    size={20}
                    color="black"
                    onClick={like}
                    className="cursor-pointer"
                  />
                )}
                <p className="font-semibold">{data?.likes}</p>
              </div>
              <IoChatbubbleEllipsesOutline
                size={24}
                onClick={() => {
                  if (!location.pathname.includes("comment")) {
                    navigate(`/forum/comment/${data.id}`);
                  }
                }}
                className={
                  location.pathname.includes("comment") ? "" : "cursor-pointer"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:hidden w-full">
        <img
          src={data?.image}
          alt="image"
          className={`w-full max-h-[300px] rounded-xl border-2 border-black p-1 ${
            navigateToDetail ? "cursor-pointer" : ""
          }`}
          onClick={() => {
            if (navigateToDetail) {
              navigateToDetail();
            }
          }}
        />
        <div className="flex flex-col w-full relative pt-4">
          <p
            className={`text-lg text-[#262626] ${
              navigateToDetail ? "cursor-pointer" : "cursor-default"
            }`}
            style={{ fontWeight: 700 }}
            onClick={() => {
              if (navigateToDetail) {
                navigateToDetail();
              }
            }}
          >
            {data?.title}
          </p>
          <div className="flex flex-col gap-2">
            <p className="text-sm text-[#92929D] font-normal">
              {data?.description !== undefined &&
                (data.description.length > 255
                  ? data?.description.slice(0, 250) + "..."
                  : data?.description)}
            </p>
          </div>
          <div className="flex justify-between w-full items-center relative bottom-0 pt-4">
            <div className="flex gap-4 items-center">
              <Avatar
                src="https://docs.material-tailwind.com/img/face-2.jpg"
                alt="avatar"
                variant="circular"
              />
              <div className="flex flex-col">
                <p className="text-lg font-semibold text-black">Moderator</p>
                <p className="text-sm font-medium">{data?.moderator}</p>
              </div>
            </div>
            <div className="flex ml-2 items-center gap-4 relative z-10">
              <div className="flex gap-2 items-center">
                {data?.isLiked ? (
                  <IoHeart
                    size={20}
                    color="#F65858"
                    onClick={like}
                    className="cursor-pointer"
                  />
                ) : (
                  <IoHeartOutline
                    size={20}
                    color="black"
                    onClick={like}
                    className="cursor-pointer"
                  />
                )}
                <p className="font-semibold">{data?.likes}</p>
              </div>
              <IoChatbubbleEllipsesOutline
                size={24}
                onClick={() => {
                  if (!location.pathname.includes("comment")) {
                    navigate(`/forum/comment/${data.id}`);
                  }
                }}
                className={
                  location.pathname.includes("comment") ? "" : "cursor-pointer"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default ForumCard;
