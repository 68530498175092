import DateCalendarValue from "components/date/Calendar";
import CardEvent from "components/event/CardEvent";
import Header from "components/shared/header";
import Sidebar from "components/shared/sidebar";
import Container from "layout/container";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  useEventByMonthQuery,
  useOngoingEventQuery,
  useUpcomingEventQuery,
} from "services/modules/event";
export const eventRouteName = "event";
const EventCalendar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [navbarActive, setNavbarActive] = useState<boolean>(false);
  const [rightbarActive, setRightbarActive] = useState<boolean>(false);
  const toggleSidebar = (): void => {
    setNavbarActive((prev) => !prev);
    setRightbarActive(false);
  };
  const toggleRightbar = (): void => {
    setRightbarActive((prev) => !prev);
    setNavbarActive(false);
  };

  const [value, setValue] = React.useState(moment(new Date()));
  const year = moment(value).format("YYYY");
  const month = moment(value).format("MM");
  const [params, setParams] = React.useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const { data, isLoading } = useOngoingEventQuery();
  const { data: upcomingData, isLoading: isLoadingUpcoming } =
    useUpcomingEventQuery(params);
  const { data: monthEventData } = useEventByMonthQuery({
    month,
    year,
  });
  return (
    <>
      <Helmet>
        <title>{t("Spix | Community")}</title>
      </Helmet>
      <div className="block lg:flex lg:flex-row">
        <Sidebar active={navbarActive} toggleSidebar={toggleSidebar} />
        <Container className={`${"w-full lg:w-[60%] lg:relative"}`}>
          <Header
            className={`${"w-full lg:absolute"}`}
            toggleSidebar={toggleSidebar}
            toggleRightbar={toggleRightbar}
          />
          <div className="flex flex-col px-4 pt-5">
            <div className="flex justify-between pb-5">
              <p className="text-lg font-semibold text-[#1C2630]">
                Ongoing Events
              </p>
              <p
                className="text-sm font-light text-[#333333] cursor-pointer hover:underline"
                onClick={() => {
                  navigate("/event/type/Ongoing");
                }}
              >
                View All
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {data?.data.slice(0, 2).map((el) => {
                return <CardEvent data={el} key={el.id} type="Ongoing" />;
              })}
            </div>
            {data?.data.length === 0 && (
              <div className="flex lg:justify-center justify-start">
                <p className="text-sm font-medium text-[#1C2630]">
                  Tidak ada Event
                </p>
              </div>
            )}
            <div className="flex justify-between py-5">
              <p className="text-lg font-semibold text-[#1C2630]">
                Upcoming Events
              </p>
              <p
                className="text-sm font-light text-[#333333] cursor-pointer hover:underline"
                onClick={() => {
                  navigate("/event/type/Upcoming");
                }}
              >
                View All
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {upcomingData?.data.slice(0, 2).map((el) => {
                return <CardEvent data={el} key={el.id} type="Upcoming" />;
              })}
            </div>
            {upcomingData?.data.length === 0 && (
              <div className="flex lg:justify-center justify-start">
                <p className="text-sm font-medium text-[#1C2630]">
                  Tidak ada Event
                </p>
              </div>
            )}
            <div className="flex justify-between py-5">
              <p className="text-lg font-semibold text-[#1C2630]">
                {moment(value).utc().format("MMMM YYYY")} Events
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {monthEventData?.data.map((el) => {
                return <CardEvent data={el} key={el.id} type="Upcoming" />;
              })}
            </div>
          </div>
        </Container>
        <div className="bg-white pt-4 hidden lg:block">
          <DateCalendarValue setValue={setValue} value={value} />
        </div>
        {rightbarActive ? (
          <>
            <div className="fixed z-[10] top-0 left-0 w-full h-full bg-black opacity-20 lg:hidden"></div>
            <div className="fixed left-6 md:left-[40%] z-50 top-6">
              <FiX
                size={30}
                className="z-10 cursor-pointer text-black"
                onClick={toggleRightbar}
              />
            </div>
          </>
        ) : null}
        <div
          className={`${
            rightbarActive ? "w-[80%] md:w-[50%] z-50" : "w-0"
          } fixed lg:hidden top-0 right-0 bg-white min-h-full shadow-xl`}
        >
          <DateCalendarValue setValue={setValue} value={value} />
        </div>
      </div>
    </>
  );
};
export default EventCalendar;
