import { NavLink, useLocation } from "react-router-dom";
import { menuItems } from "data/sidebar-menu";
import { Button, Modal } from "react-daisyui";
import { FiLogOut } from "react-icons/fi";
import { ForwardRefRenderFunction, useCallback, useRef, useState } from "react";
import { forwardRef } from "react";
import { useAppDispatch } from "store";
import { deleteTokenAuth } from "store/auth";
import { SpixLogo } from "assets/images";
import { IoChevronBackOutline } from "react-icons/io5";

interface SidebarProps {
  active: boolean;
  toggleSidebar: () => void;
}

interface ModalLogoutProps {
  ref: React.DialogHTMLAttributes<HTMLDialogElement>;
  handleClose: () => void;
  handleLogout: () => void;
}

const menuStyles = {
  active:
    "transition-all duration-100 p-4 bg-white cursor-pointer rounded-l-full ml-2",
  inactive:
    "hover:bg-slate-100 hover:bg-opacity-20 transition-all duration-100 p-4 cursor-pointer ml-2 rounded-l-full text-white",
};

const LogoutModal: ForwardRefRenderFunction<
  HTMLDialogElement,
  ModalLogoutProps
> = ({ handleClose, handleLogout }, ref) => {
  return (
    <div className="font-sans">
      <Modal backdrop={true} ref={ref} className="bg-white">
        <Modal.Header className="font-bold">
          Are you sure want to log out?
        </Modal.Header>
        <Modal.Body>
          Press ESC key or click the button below to close
        </Modal.Body>
        <Modal.Actions>
          <Button onClick={handleLogout} className="text-white bg-red-500">
            Yes, sure
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

const ForwardedRefLogoutModal = forwardRef(LogoutModal);

const Sidebar: React.FC<SidebarProps> = ({
  active,
  toggleSidebar,
}): JSX.Element => {
  const [menus, setMenus] = useState(menuItems);
  const location = useLocation();

  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDialogElement>(null);

  const handleShowDialog = useCallback(() => {
    modalRef.current?.showModal();
  }, [modalRef]);

  const handleCloseDialog = useCallback(() => {
    modalRef.current?.close();
  }, [modalRef]);

  const handleLogout = () => {
    dispatch(deleteTokenAuth());
  };

  return (
    <>
      {active ? (
        <div className="fixed z-[99] top-0 left-0 w-full h-full bg-black opacity-50 lg:hidden"></div>
      ) : null}
      <aside
        className={`bg-white ${
          active ? "w-[60%]" : "hidden lg:block"
        } fixed lg:relative z-[999] left-0 inset-0 lg:inherit lg:w-[20%]`}
      >
        <div className="pl-4 pr-2">
          <div className="lg:flex hidden w-full flex-col justify-center items-center gap-2">
            <img src={SpixLogo} width={250} />
          </div>
          <div className="lg:hidden flex w-full flex-row justify-between items-center gap-2">
            <img src={SpixLogo} width={150} className="object-cover" />
            <div
              className="flex relative cursor-pointer"
              onClick={toggleSidebar}
            >
              <IoChevronBackOutline
                size={20}
                className="absolute right-2"
                color="#ADADAD"
              />
              <IoChevronBackOutline size={20} color="#ADADAD" />
            </div>
          </div>
        </div>

        <div className="select-none px-4 mb-20">
          <ul className="flex flex-col">
            {menus.map((item, index) => {
              return (
                <>
                  <NavLink
                    key={index}
                    to={item.path}
                    className={({ isActive }) =>
                      location.pathname.includes(item.path) && item.path !== "#"
                        ? "bg-[#1C3951] rounded-xl shadow-xl"
                        : "rounded-xl"
                    }
                    onClick={() => {
                      if (item.child) {
                        setMenus((prev) => {
                          let temp = [...prev];
                          temp[index].expand =
                            temp[index].expand === undefined
                              ? true
                              : !temp[index].expand;
                          return temp;
                        });
                      }
                    }}
                  >
                    <li
                      className={`flex items-center gap-4 hover:bg-[#1C3951] rounded-xl font-medium hover:bg-opacity-20 transition-all duration-100 p-4 cursor-pointer ml-2 ${
                        location.pathname.includes(item.path)
                          ? "text-white"
                          : "text-[#ADADAD]"
                      }`}
                    >
                      {location.pathname.includes(item.path)
                        ? item.activeIcon
                        : item.icon}
                      {item.name}
                    </li>
                  </NavLink>
                  {item.expand && (
                    <ul className="whitespace-nowrap list-disc pl-10">
                      {item.child &&
                        item.child.map((child, i) => (
                          <NavLink
                            key={i}
                            to={child.path}
                            className={({ isActive }) => {
                              return isActive
                                ? "bg-spix rounded-xl"
                                : "rounded-xl";
                            }}
                          >
                            <li className="hover:bg-spix hover:bg-opacity-20 transition-all duration-100 p-4 cursor-pointer ml-2 rounded-l-full text-white">
                              {child.name}
                            </li>
                          </NavLink>
                        ))}
                    </ul>
                  )}
                </>
              );
            })}
            <div
              className={`${
                active ? "left-0 lg:w-[20%] w-[60%] fixed" : "lg:w-[20%] fixed"
              } bottom-5 px-4 left-0 transition-all`}
            >
              <Button
                onClick={handleShowDialog}
                className="w-full bg-red-500 hover:bg-red-400 border-0 text-white"
                shape="circle"
              >
                <FiLogOut size={20} />
                Log out
              </Button>
            </div>
          </ul>
        </div>

        <ForwardedRefLogoutModal
          ref={modalRef}
          handleLogout={handleLogout}
          handleClose={handleCloseDialog}
        />
      </aside>
    </>
  );
};

export default Sidebar;
