import { Card } from "@material-tailwind/react";
import useUpdatePostForm from "hooks/post/UseUpdateForm";
import { Modal } from "react-daisyui";
import { useFieldArray } from "react-hook-form";
import { useEffect, useState } from "react";
import { DeleteIcon, Folder, ImageIcon, PDF } from "assets/images";
import { Button } from "react-daisyui";
import { usePostDetailQuery } from "services/modules/post";
import { UpdatePostForm } from "_interfaces/post.interface";
import PDFViewer from "components/post/PDFViewer";
import useFilePreview from "hooks/shared/useFilePreview";
import { toast } from "react-toastify";
import usePdfPreview from "hooks/shared/usePdfPreview";
import { errorHandler } from "services/errorHandler";

interface UpdatePostProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  success: () => void;
}

const UpdatePostPage: React.FC<UpdatePostProps> = ({
  isOpen,
  onClose,
  id,
  success,
}) => {
  const { data, refetch } = usePostDetailQuery({ id: id! });

  const [isImageUploadOpen, setIsImageUploadOpen] = useState(false);
  const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
  const [modalPDF, setModalPDF] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    register,
    errors,
    setFocus,
    isLoading,
    watch,
    handleCreate,
    control,
    reset,
    setValue,
    create,
    handleSubmit,
  } = useUpdatePostForm(id);

  const gridStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "16px",
    marginTop: "16px",
  };

  const { fields, remove, insert } = useFieldArray({
    control,
    name: "images",
  });

  const image = watch("images");

  const imagePreview = image?.map(({ file }) => {
    if (file && file[0]) {
      return URL.createObjectURL(file[0]);
    }
  });

  const file = watch("file");
  // const [filePreview] = useFilePreview(file.file);
  const filePreview = usePdfPreview(file.file);

  useEffect(() => {
    if (data?.data) {
      let tempImages = data.data.images?.map((item) => ({ link: item }));
      let temp: UpdatePostForm = {
        text: data.data.text,
        images: tempImages,
        file: {
          link: data.data.file ?? "",
        },
      };
      reset(temp);
    }
  }, [isOpen, data]);

  const onSubmit = async (formData: UpdatePostForm) => {
    try {
      await create(formData);
      reset();
      onClose();
      success();
      toast.success("Post successfully updated");
      setValue(`file`, { file: undefined, link: "" });
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleFileClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    event.preventDefault();
    const fileInput = document.getElementById(`fileInput`) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
      fileInput.onchange = (e) => {
        const files = (e.target as HTMLInputElement).files;

        if (files && files.length > 0) {
          setValue(`file.file`, files);
        }
      };
    }
  };

  useEffect(() => {
    if (isOpen) {
      refetch();
    }

    const indexFields = fields.length - 1;

    if (image[indexFields].link !== "") {
      insert(fields.length, { link: "" });
    }
  }, [isOpen]);

  const handleImgClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    event.preventDefault();

    const newFieldIndex = fields.length - 1;
    const imgInput = document.getElementById(
      `imgInput-${newFieldIndex}`
    ) as HTMLInputElement;

    if (imgInput) {
      imgInput.click();
      imgInput.onchange = (e) => {
        const files = (e.target as HTMLInputElement).files;

        if (files && files.length > 0) {
          setValue(`images.${newFieldIndex}.file`, files);
          if (fields.length < 5) {
            insert(fields.length, { link: "" });
          }
        }
      };
    }
  };

  useEffect(() => {
    if (fields.length === 5) {
      setIsDisabled(true);
    } else if (fields.length < 5) {
      setIsDisabled(false);
    }
  }, [fields.length]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        backdrop={false}
        open={isOpen}
        className="bg-white w-screen max-w-2xl"
        responsive={false}
      >
        <Modal.Header className="mx-5">
          <div
            className="absolute right-4 top-4"
            onClick={onClose}
            style={{ cursor: "pointer" }}
          >
            ✕
          </div>
          <h3 className="text-md text-black font-bold">Update Post</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col px-5 pb-5">
            <div className="rounded-md w-full mb-4 outline-none bg-[#F6F6F6]">
              <textarea
                placeholder="Type a text"
                className="rounded-md w-full px-3 py-2 outline-none resize-none bg-[#F6F6F6]"
                style={{ height: "auto", overflowY: "hidden" }}
                {...register("text")}
                onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  const textarea = e.target;
                  textarea.style.height = "auto";
                  textarea.style.height = `${textarea.scrollHeight}px`;
                }}
              />
              {(filePreview && file.file) || file.link ? (
                <>
                  <div className="flex justify-between items-center cursor-pointer border border-gray-400 m-4 p-2">
                    <div
                      className="flex items-center"
                      onClick={() => {
                        setModalPDF(true);
                      }}
                    >
                      <img src={PDF} alt="pdf" className="w-12 h-12" />
                      {file.file && <p>{file.file[0]?.name}</p>}
                    </div>
                    <div
                      className="rounded-full p-2 bg-gray-300"
                      onClick={() => {
                        setValue(`file`, { file: undefined, link: "" });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={DeleteIcon} alt="Delete" />
                    </div>
                  </div>
                  {modalPDF && (
                    <PDFViewer
                      file={(filePreview ?? file.link) as string}
                      isOpen={modalPDF}
                      onClose={() => setModalPDF(false)}
                    />
                  )}
                </>
              ) : (
                <></>
              )}
              <div style={gridStyles} className="w-full">
                {fields.map((item, i) => (
                  <div key={i}>
                    <div>
                      {(imagePreview[i] || item.link) && (
                        <div className="relative">
                          <div
                            className="absolute right-4 top-4 rounded-full p-2 bg-gray-300"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              remove(i);
                            }}
                          >
                            <img src={DeleteIcon} alt="Delete" />
                          </div>
                          <img
                            src={imagePreview[i] ?? item.link}
                            alt={`Image ${i}`}
                            className="h-[160px] w-full object-cover"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex justify-left items-center">
                <div>
                  <input
                    id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    {...register("file.file")}
                  />
                  <img
                    src={Folder}
                    className="mr-4"
                    onClick={(e) => handleFileClick(e)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div>
                  <img
                    src={ImageIcon}
                    onClick={(e) => handleImgClick(e)}
                    style={{ cursor: "pointer" }}
                  />
                  {fields.map((item, i) => (
                    <div key={i}>
                      <input
                        id={`imgInput-${i}`}
                        type="file"
                        style={{ display: "none" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <Button
                type="submit"
                className="bg-san-juan hover:bg-san-juan text-white"
              >
                Publish
              </Button>
            </div>
            {errors.text && (
              <p className="text-red-400 italic text-sm">
                {errors.text.message}
              </p>
            )}
            {/* {errors.images && errors.images.type === "fileSize" && (
              <p className="text-red-400 italic text-sm">
                {errors.images.message}
              </p>
            )}
            {errors.images && errors.images.type === "fileOrLink" && (
              <p className="text-red-400 italic text-sm">
                {errors.images.message}
              </p>
            )}
            {errors.file && errors.file.type === "fileSize" && (
              <p className="text-red-400 italic text-sm">
                {errors.file.message}
              </p>
            )}
            {errors.file && errors.file.type === "fileOrLink" && (
              <p className="text-red-400 italic text-sm">
                {errors.file.message}
              </p>
            )} */}
          </div>
        </Modal.Body>
      </Modal>
    </form>
  );
};

export default UpdatePostPage;
