import {
  DefaultAvatar,
  NotifNormalSVG,
  NotifSVG,
  SpixCommunityLogoGray,
} from "assets/images";
import SearchInput from "components/search-input";
import { HeaderName } from "data/header-name";
import { useState } from "react";
import { Avatar, Menu } from "react-daisyui";
import { FiSettings, FiUser } from "react-icons/fi";
import {
  IoChevronBackOutline,
  IoMenuOutline,
  IoArrowBack,
} from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Notification from "./Notification";
import { useGetProfileQuery } from "services/modules/profile";
import {
  useNotificationCountQuery,
  useReadNotificationMutation,
} from "services/notification";
import { SearchBox } from "components/shared/header";

interface HeaderProps {
  toggleSidebar: () => void;
  className?: string;
  toggleRightbar?: () => void;
  headerTitle?: string;
  onSearch: (searchTerm: string) => void;
  isBack?: boolean;
}

interface HeaderMenuProps {}

const HeaderMenu: React.FC<HeaderMenuProps> = (): JSX.Element => {
  return (
    <Menu className="z-[999] absolute top-[60px] right-0 bg-white w-56">
      <Menu.Item>
        <Link to="#" className="flex items-center gap-2">
          <FiUser size={20} />
          <span className="font-bold">Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="#" className="flex items-center gap-2">
          <FiSettings size={20} />
          <span className="font-bold">Settings</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

const PostHeader: React.FC<HeaderProps> = ({
  toggleSidebar,
  className,
  toggleRightbar,
  headerTitle,
  onSearch,
  isBack,
}): JSX.Element => {
  const navigate = useNavigate();
  const [toolbarMenuVisible, setToolbarMenuVisible] = useState<boolean>(false);
  const toggleToolbarMenuVisible = () => setToolbarMenuVisible((prev) => !prev);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const { data } = useGetProfileQuery();
  const [markAllAsReadMutation] = useReadNotificationMutation();
  const { data: NotifCount, refetch } = useNotificationCountQuery();
  const [notificationVisible, setNotificationVisible] =
    useState<boolean>(false);
  const toggleNotificationVisible = () =>
    setNotificationVisible((prev) => !prev);

  return (
    <>
      <nav
        className={`lg:flex fixed z-[998] select-none h-[60px] bg-[#F6F6F6] py-4 pt-14 px-8 hidden top-0 justify-between items-center ${className}`}
      >
        {isBack && (
          <button onClick={() => navigate(-1)}>
            <IoArrowBack size={20} />
          </button>
        )}
        <div className="p-2">
          <h1 className="text-2xl font-bold text-black">{headerTitle}</h1>
        </div>
        <div className="flex items-center gap-4 p-2 relative">
          <SearchBox />
          {/* <SearchInput
            onSubmit={({ text }) => onSearch(text)}
            placeholder="Search"
          /> */}
          {NotifCount?.data === 0 ? (
            <img
              src={NotifNormalSVG}
              alt="notification"
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                toggleNotificationVisible();
              }}
            />
          ) : (
            <img
              src={NotifSVG}
              alt="notification"
              className="w-6 h-6 cursor-pointer"
              onClick={async () => {
                await markAllAsReadMutation();
                await refetch();
                toggleNotificationVisible();
              }}
            />
          )}
          {notificationVisible && <Notification isOpen={notificationVisible} />}
          <Avatar
            size="xs"
            shape="circle"
            src={data?.data.avatar ? data.data.avatar : DefaultAvatar}
          />
          {toolbarMenuVisible && <HeaderMenu />}
        </div>
      </nav>
      <nav
        className={`bg-white lg:hidden z-[998] flex flex-col select-none h-fit mb-2 top-0 ${className}`}
      >
        <div className="flex justify-between items-center">
          <div className="p-2">
            <img src={SpixCommunityLogoGray} width={150} />
          </div>
          <div className="flex items-center gap-4 p-2 relative">
            <SearchBox />
            {NotifCount?.data === 0 ? (
              <img
                src={NotifNormalSVG}
                alt="notification"
                className="w-6 h-6 cursor-pointer"
                onClick={() => {
                  navigate("/notification");
                }}
              />
            ) : (
              <img
                src={NotifSVG}
                alt="notification"
                className="w-6 h-6 cursor-pointer"
                onClick={async () => {
                  await markAllAsReadMutation();
                  await refetch();
                  navigate("/notification");
                }}
              />
            )}

            <Avatar
              onClick={toggleToolbarMenuVisible}
              className="cursor-pointer"
              size="xs"
              shape="circle"
              src={DefaultAvatar}
            />
            {toolbarMenuVisible && <HeaderMenu />}
          </div>
        </div>
        <div className="flex justify-between items-center bg-white p-2 w-full">
          <IoMenuOutline
            size={30}
            className="flex relative cursor-pointer"
            onClick={toggleSidebar}
          />
          <div className="p-2">
            <h1 className="text-base font-bold text-black">{headerTitle}</h1>
          </div>
          <div className="min-w-[30px]">
            <div
              className={`${
                toggleRightbar !== undefined ? "flex" : "hidden"
              } relative cursor-pointer`}
              onClick={toggleRightbar}
            >
              <IoChevronBackOutline
                size={20}
                className="absolute right-4"
                color="#ADADAD"
              />
              <IoChevronBackOutline size={20} color="#ADADAD" />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default PostHeader;
