import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useVerifyEmailMutation } from "services/modules/auth";
import { ErrorData, errorHandler } from "services/errorHandler";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [verifyEmail, { isLoading, isError, isSuccess, error }] =
    useVerifyEmailMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      verifyEmail({ token });
    }
  }, [token]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [isSuccess]);

  if (isLoading) {
    return (
      <p className="text-center w-full h-screen flex justify-center items-center">
        Verifying...
      </p>
    );
  }

  if (isError) {
    const err = error as FetchBaseQueryError;
    const errorData = err.data as ErrorData;
    return (
      <p className="text-center w-full h-screen flex justify-center items-center">
        {errorData?.message ?? "Unkown Error!"}
      </p>
    );
  }

  if (isSuccess) {
    return (
      <p className="text-center w-full h-screen flex justify-center items-center">
        Your email successfully verified. Redirecting to login.
      </p>
    );
  }

  return (
    <p className="text-center w-full h-screen flex justify-center items-center">
      Mail Verification
    </p>
  );
};

export default VerifyEmail;

