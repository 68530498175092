import * as React from "react";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
interface props {
  value: moment.Moment;
  setValue: React.Dispatch<React.SetStateAction<moment.Moment>>;
}
export default function DateCalendarValue({ value, setValue }: props) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateCalendar
        value={value}
        onChange={(newValue) => setValue(newValue)}
        sx={{
          "& .MuiPickersDay-dayWithMargin.Mui-selected": {
            backgroundColor: "#1C3951",
          },
        }}
      />
    </LocalizationProvider>
  );
}
