import {
  CreateBusinessInfoForm,
  CreateBusinessInfoReq,
  CreatePortfolioReq,
} from "_interfaces/user.interface";
import { ImageIcon } from "assets/images";
import useCreateBusinessInfoForm from "hooks/profile/useCreateBusinessInfoForm";
import useCreatePortfolioForm from "hooks/profile/useCreatePortfolioForm";
import usePdfPreview from "hooks/shared/usePdfPreview";
import { useState } from "react";
import { Button } from "react-daisyui";
import { toast } from "react-toastify";
import { errorHandler } from "services/errorHandler";

export default function CreatePortfolio({
  onClose,
  onCloseSuccess,
  id,
}: {
  onClose: () => void;
  onCloseSuccess: () => void;
  id: string;
}): React.ReactElement {
  const {
    register,
    errors,
    reset,
    setFocus,
    handleSubmit,
    create,
    isLoading,
    handleCreate,
    setValue,
  } = useCreatePortfolioForm();

  const onSubmit = async () => {
    try {
      await handleCreate();
      onCloseSuccess();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full bg-white p-6 shadow-lg rounded-lg">
          <h5 className="text-md font-semibold mb-4">Create Portfolio</h5>
          <div className="block lg:grid lg:grid-cols-2 gap-4 mb-4">
            <div className="mb-4 lg:mb-0">
              <input type="hidden" defaultValue={id as string} {...register("bussinesId")} />
              <label className="block text-sm font-medium text-gray-700">
                Client
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                {...register("client")}
              />
              <p className="text-red-500">{errors.client?.message}</p>
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                Project
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                {...register("project")}
              />
              <p className="text-red-500">{errors.project?.message}</p>
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                {...register("address")}
              />
              <p className="text-red-500">{errors.address?.message}</p>
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                Start Date
              </label>
              <input
                type="date"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                {...register("start_date")}
              />
              <p className="text-red-500">{errors.start_date?.message}</p>
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                End Date
              </label>
              <input
                type="date"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                {...register("end_date")}
              />
              <p className="text-red-500">{errors.end_date?.message}</p>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              rows={3}
              {...register("description")}
            ></textarea>
            <p className="text-red-500">{errors.description?.message}</p>
          </div>

          <div className="mt-6 flex justify-end gap-4">
            <Button
              type="submit"
              className="bg-san-juan text-white hover:bg-san-juan hover:text-white"
              loading={isLoading}
            >
              Save
            </Button>
            <Button
              className="bg-gray-300 hover:bg-gray-300 border-none"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
