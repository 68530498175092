import { Avatar } from "@mui/material";
import { CommentList, Forum } from "_interfaces/forum.interface";
import CommentCard from "components/forum/CommentCard";
import ForumCard from "components/forum/ForumCard";
import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useForumCommentListQuery,
  useForumDetailQuery,
  useLikeForumMutation,
  usePostCommentMutation,
} from "services/modules/forum";
export const forumCommentRouteName = "forum/comment/:id";
import Loading from "../../assets/animation/loading.json";
import Lottie from "lottie-react";
import { DefaultAvatar } from "assets/images";
import { useAppSelector } from "store";

const ForumCommentPage = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const { id } = useParams();
  const [commentList, setCommentList] = useState<CommentList[]>([]);
  const [comment, setComment] = useState("");
  const [params, setParams] = React.useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const { data, refetch } = useForumDetailQuery({
    id: id as string,
  });
  const [likeForum] = useLikeForumMutation();
  const {
    data: dataComment,
    isLoading: isLoadingComment,
    refetch: refetchComment,
  } = useForumCommentListQuery({ id: id as string, ...params });
  const [createComment] = usePostCommentMutation();
  useEffect(() => {
    if (params.page === 1) {
      setCommentList(dataComment?.data as CommentList[]);
    } else {
      setCommentList((prev) => [
        ...prev,
        ...(dataComment?.data as CommentList[]),
      ]);
    }
  }, [dataComment]);

  return (
    <div className="flex flex-col px-4 pt-5 pb-[25%] sm:pb-[20%] md:pb-[15%] lg:pb-[10%]">
      <div className="grid grid-cols-1 gap-4">
        <ForumCard
          data={data?.data as Forum}
          navigateToDetail={() => {
            navigate(`/forum/${id as string}`);
          }}
          like={async () => {
            try {
              const res = await likeForum({ forumId: id as string }).unwrap();
              if (res) {
                await refetch();
                toast.success(res.message);
              }
            } catch (error) {
              toast.error("error when like forum");
            }
          }}
        />
      </div>
      <div className="flex flex-col gap-4">
        {commentList?.map((el: CommentList) => (
          <CommentCard data={el} key={el.id} refetch={refetchComment} />
        ))}
        {dataComment?.data.length !== undefined &&
          dataComment?.meta?.hasNextPage && (
            <button
              onClick={() => {
                setParams((prev) => ({ ...prev, page: prev.page + 1 }));
              }}
            >
              load more
            </button>
          )}
        {isLoadingComment && params.page > 1 && (
          <div className="flex justify-center">
            <Lottie
              animationData={Loading}
              loop={true}
              className="max-w-[100px]"
            />
          </div>
        )}
      </div>
      <div className="fixed bottom-0 right-0 w-full lg:w-[80%]">
        <div className="p-3 lg:p-5 flex items-center gap-4 w-full justify-between bg-white">
          <Avatar
            src={user?.avatar ?? DefaultAvatar}
            alt="avatar"
            variant="circular"
          />
          <Input
            type="text"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            placeholder="Type a comment"
            className="w-[90%] border-none bg-neutral-200 p-2 px-4 rounded-xl focus:outline-none outline-none"
          />
          <div
            className={`flex items-center ${
              comment.length > 0 ? "cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={async () => {
              try {
                if (comment.length > 0) {
                  await createComment({ text: comment, forumId: id as string });
                  await refetchComment();
                  setComment("");
                } else {
                  toast.error("Type your comment first");
                }
              } catch (error) {
                toast.error("Unknown Error");
              }
            }}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.5806 3.44248C27.9136 2.75797 26.9266 2.50295 26.0062 2.77139L4.54408 9.01254C3.57301 9.28231 2.88473 10.0568 2.69932 11.0406C2.50991 12.0418 3.17151 13.3129 4.03587 13.8444L10.7466 17.9689C11.4349 18.3917 12.3233 18.2857 12.8928 17.7112L20.5773 9.97891C20.9641 9.57625 21.6044 9.57625 21.9912 9.97891C22.378 10.3681 22.378 10.999 21.9912 11.4016L14.2934 19.1353C13.7225 19.7084 13.6158 20.6009 14.036 21.2935L18.1363 28.0715C18.6165 28.8768 19.4435 29.3332 20.3505 29.3332C20.4573 29.3332 20.5773 29.3332 20.684 29.3197C21.7244 29.1855 22.5514 28.4742 22.8582 27.4675L29.2208 6.03289C29.501 5.12021 29.2475 4.12699 28.5806 3.44248Z"
                fill="#B4C1C8"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForumCommentPage;
