import { RegisterForm, RegisterReqI } from "_interfaces/auth-api.interfaces";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { useRegisterMutation } from "services/modules/auth";
import { toast } from "react-toastify";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";
interface props {
  setType: Dispatch<SetStateAction<"BUSINESS" | "KOL" | "Verification" | "CP">>;
  setErrorPassword: Dispatch<SetStateAction<string>>;
  registrationType: "BUSINESS" | "KOL" | "Verification" | "CP";
}
const useRegisterForm = (props: props) => {
  const navigate = useNavigate();
  const [registration, { isLoading }] = useRegisterMutation();
  const schema = yup
    .object({
      email: yup.string().email().required(),
      phone_number: yup.string().required(),
      gender: yup.string().required(),
      name: yup.string().required(),
      company_address: yup.string().required(),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    control,
    reset,
    setValue,
  } = useForm<RegisterForm>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      newPassword: "",
      password: "",
    },
  });

  useEffect(() => {
    if (props.registrationType === "BUSINESS") {
      setValue("business_scale", "");
      setValue("business_sector", "");
    }
    if (props.registrationType === "KOL") {
      setValue("business_scale", "small");
      setValue("business_sector", "KOL");
    }
  }, [props.registrationType]);

  const create = async (data: RegisterForm) => {
    try {
      if (data.newPassword !== data.password) {
        props.setErrorPassword("Password Doesn't match");
      } else if (data.newPassword) {
        const payload: RegisterReqI = {
          name: data.name,
          phone_number: data.phone_number,
          email: data.email,
          gender: data.gender,
          type: props.registrationType === "KOL" ? "KOL" : "BUSINESS",
          business_scale: data.business_scale,
          password: data.password,
          business_sector: data.business_sector,
          company_name: data.business_name,
          company_address: data.company_address,
        };
        const res = await registration(payload).unwrap();
        if (res.success) {
          toast.success("Account Created");
          props.setType("Verification");
        }
      } else if (Object.keys(errors).length === 0) {
        props.setType("CP");
      }
    } catch (error: any) {
      toast.error(error.data.message[0]);
      errorHandler(error);
    }
  };

  const handleRegister = handleSubmit(create);

  return {
    handleSubmit,
    register,
    errors,
    watch,
    control,
    handleRegister,
    isLoading,
    reset,
  };
};

export default useRegisterForm;

