import { Navigate, RouteObject } from "react-router-dom";
import Login from "pages/login";
import DashboardLayout from "layout/dashboard";
import AuthLayout from "layout/auth";
import ForgotPassword from "pages/forgot-password";
import Register from "pages/register";
import DetailPostPage, { detailPostRouteName } from "pages/post/detail.page";
import EventCalendar, { eventRouteName } from "pages/event-calendar";
import DetailEventCalendar, {
  eventDetailRouteName,
} from "pages/event-calendar/detail.page";
import PostPage from "pages/post/index";
import ForumPage, { forumRouteName } from "pages/forum/index.page";
import ForumCommentPage, {
  forumCommentRouteName,
} from "pages/forum/comment.page";
import DetailForum, { forumDetailRouteName } from "pages/forum/detail.page";
import SpecificEventCalendar, {
  specificEventRouteName,
} from "pages/event-calendar/specific.page";
import HomeLayout from "layout/home";
import MyProfile, { myProfileRouteName } from "pages/profile/index.pages";
import EditProfile, { editProfileRouteName } from "pages/profile/update.pages";
import OtherProfile, { otherProfileRouteName } from "pages/profile/profileFriend.pages";
import NotificationPage, { notificationRouteName } from "pages/notification";
import VerifyEmail from "pages/verify-email";


const protectedRoutes: RouteObject[] = [
  { path: "*", element: <Navigate to="/post" /> },
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      { path: "not-found", element: <>Page Not Found</> },
      { path: forumRouteName, element: <ForumPage /> },
      { path: forumCommentRouteName, element: <ForumCommentPage /> },
      { path: forumDetailRouteName, element: <DetailForum /> },
    ],
  },
  {
    path: eventRouteName,
    element: <EventCalendar />,
  },
  {
    path: eventDetailRouteName,
    element: <DetailEventCalendar />,
  },
  {
    path: specificEventRouteName,
    element: <SpecificEventCalendar />,
  },

  { path: "post", element: <PostPage /> },
  { path: detailPostRouteName, element: <DetailPostPage /> },

  {
    path: "",
    element: <DashboardLayout />,
    children: [
      { path: myProfileRouteName, element: <MyProfile /> },
      { path: editProfileRouteName, element: <EditProfile /> },
      { path: otherProfileRouteName, element: <OtherProfile /> },
    ],
  },
  { path: notificationRouteName, element: <NotificationPage /> },

];

const publicRoutes: RouteObject[] = [
  {
    path: "",
    element: <AuthLayout />,
    children: [
      { path: "", element: <Login /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "register", element: <Register /> },
      { path: "verify", element: <VerifyEmail /> },
    ],
  },
  { path: "404", element: <div>Not Found</div> },
  { path: "*", element: <Navigate to="/" /> },
];

export { publicRoutes, protectedRoutes };
