import moment from "moment";
import {
  IoCalendarOutline,
  IoLocationOutline,
  IoTodayOutline,
} from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useForumDetailQuery } from "services/modules/forum";
export const forumDetailRouteName = "forum/:id";
const DetailForum = () => {
  const { id } = useParams();
  const { data, isLoading } = useForumDetailQuery({ id: id as string });
  return (
    <div className="flex flex-col px-4 pt-5">
      <div className="grid grid-cols-1">
        <div className="flex flex-col bg-white w-full rounded-2xl overflow-hidden">
          <img
            src={data?.data.image}
            alt="banner"
            className="w-full max-h-[325px] object-fill"
          />
          <div className="flex flex-col pb-10 p-4 gap-1">
            <div className="flex items-center gap-2">
              <IoTodayOutline size={20} />
              <p className="text-base font-bold text-[#333333]">
                {data?.data.title}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <IoCalendarOutline size={20} />
              <p className="text-sm font-medium text-[#333333]">
                {moment(data?.data.date).format("dddd, D MMMM YYYY")}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <IoLocationOutline size={20} />
              <p className="text-sm font-medium text-[#333333]">
                {data?.data.location}
              </p>
            </div>
            <div className="flex items-center pl-7">
              <p className="text-sm font-medium text-[#333333]">
                {data?.data.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailForum;
