import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-daisyui";
import {
  useGetBusinessInfoQuery,
  useGetOtherBusinessInfoQuery,
  useGetPortfolioQuery,
} from "services/modules/profile";
import { IoAddCircleOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import CreateBusiness from "./create.pages";
import UpdateBusiness from "./update.pages";
import { BusinessInfo, User } from "_interfaces/user.interface";
import moment from "moment";
import CreatePortfolio from "./createPortfolio.pages";
import { useParams } from "react-router-dom";
import { Loader } from "components/spinner/loader";

interface props {
  id: string;
  userData: User;
}

const BusinessInfoFriend: React.FC<props> = ({ id, userData }) => {
  const { data, isLoading, refetch } = useGetOtherBusinessInfoQuery({ id: id });

  const {
    data: portfolioData,
    isLoading: isLoadingPortfolio,
    refetch: refetchPortfolio,
  } = useGetPortfolioQuery();

  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(
    null,
  );

  const handleAccordionToggle = (index: number) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  const [openAccordionPortfolioIndex, setOpenAccordionPortfolioIndex] =
    useState<number | null>(null);

  const handleAccordionPortfolioToggle = (index: number) => {
    setOpenAccordionPortfolioIndex(openAccordionIndex === index ? null : index);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <div>No data found.</div>;
  }

  return (
    <div>
      <div>
        {data?.data.map((data: any, index: any) => (
          <div
            key={index}
            className="collapse collapse-arrow w-full bg-white border border-md shadow mb-4"
          >
            <input
              type="checkbox"
              checked={openAccordionIndex === index}
              onChange={() => handleAccordionToggle(index)}
            />
            {userData.type === "KOL" ? (
              <div className="collapse-title text-md font-medium">
                {data.business_sector} | {data.business_name} Followers
              </div>
            ) : (
              <div className="collapse-title text-md font-medium">
                {data.business_name} | {data.business_sector}
              </div>
            )}
            <div className="collapse-content">
              {userData.type === "BUSINESS" && (
                <>
                  <div className="flex justify-between">
                    <h5 className="text-md font-semibold mb-4">
                      Business Information
                    </h5>
                  </div>
                  <div className="block lg:flex">
                    <div className="flex-1">
                      <div className="flex items-center mb-4">
                        {data.business_logo && (
                          <img
                            src={data.business_logo}
                            alt="Business Logo"
                            className="w-10 h-10 rounded-full mr-4"
                          />
                        )}
                        <div className="flex flex-col">
                          <span className="text-sm font-semibold">
                            Business Name
                          </span>
                          <span className="text-base">
                            {data.business_name}
                          </span>
                        </div>
                      </div>
                      <div className="mb-4">
                        <span className="text-sm font-semibold">
                          Business Scale
                        </span>
                        <p className="text-base">{data.business_scale}</p>
                      </div>
                      <div>
                        <span className="text-sm font-semibold">
                          Business Sector
                        </span>
                        <p className="text-base">{data.business_sector}</p>
                      </div>
                    </div>
                    <div className="flex-1 lg:ml-4">
                      <span className="text-sm font-semibold">Description</span>
                      <p className="text-base">{data.description}</p>
                    </div>
                  </div>
                </>
              )}

              <div className="flex justify-between">
                <h5 className="text-md font-semibold my-4">Portfolio</h5>
              </div>
              {portfolioData?.data.map((portfolio: any, index: any) => (
                <>
                  {portfolio.businessId === data.id && (
                    <div
                      key={index}
                      className="collapse collapse-arrow w-full bg-white border border-md shadow mb-4"
                    >
                      <input
                        type="checkbox"
                        checked={openAccordionPortfolioIndex === index}
                        onChange={() => handleAccordionPortfolioToggle(index)}
                      />
                      <div className="collapse-title text-md font-medium">
                        {portfolio.client} | {portfolio.project}
                      </div>
                      <div className="collapse-content">
                        <div className="flex">
                          <div className="flex-1">
                            <div className="flex items-center mb-4">
                              <div className="flex flex-col">
                                <span className="text-sm font-semibold">
                                  Client
                                </span>
                                <span className="text-base">
                                  {portfolio.client}
                                </span>
                              </div>
                            </div>
                            <div className="mb-4">
                              <span className="text-sm font-semibold">
                                Project
                              </span>
                              <p className="text-base">{portfolio.project}</p>
                            </div>
                            <div>
                              <span className="text-sm font-semibold">
                                Address
                              </span>
                              <p className="text-base">{portfolio.address}</p>
                            </div>
                            <div className="mb-4">
                              <span className="text-sm font-semibold">
                                Start Date
                              </span>
                              <p className="text-base">
                                {moment(portfolio.start_date).format(
                                  "dddd, D MMMM YYYY",
                                )}
                              </p>
                            </div>
                            <div>
                              <span className="text-sm font-semibold">
                                End Date
                              </span>
                              <p className="text-base">
                                {moment(portfolio.end_date).format(
                                  "dddd, D MMMM YYYY",
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="flex-1 ml-4">
                            <span className="text-sm font-semibold">
                              Description
                            </span>
                            <p className="text-base">{portfolio.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessInfoFriend;

