import { FaUser } from "react-icons/fa";
import { IoCalendarOutline, IoHome, IoPeopleOutline } from "react-icons/io5";

interface SubMenuItem {
  name: string;
  path: string;
}
interface MenuItem {
  name: string;
  path: string;
  activeIcon: JSX.Element;
  icon: JSX.Element;
  child?: SubMenuItem[];
  expand?: boolean;
}

const menuItems: MenuItem[] = [
  {
    name: "Home",
    path: "/post",
    activeIcon: <IoHome size={20} color="white" />,
    icon: <IoHome size={20} color="#ADADAD" />,
  },
  {
    name: "Event Calendar",
    path: "/event",
    activeIcon: <IoCalendarOutline size={20} color="white" />,
    icon: <IoCalendarOutline size={20} color="#ADADAD" />,
  },
  {
    name: "Disscussion Forum",
    path: "/forum",
    activeIcon: <IoPeopleOutline size={20} color="white" />,
    icon: <IoPeopleOutline size={20} color="#ADADAD" />,
  },
  {
    name: "Profile",
    path: "/profile",
    activeIcon: <FaUser size={20} color="white" />,
    icon: <FaUser size={20} color="#ADADAD" />,
  },
];

export { menuItems };
export type { MenuItem };
