import React from "react";
import { Button, Modal } from "react-daisyui";
import ImageCropperBanner from "./ImageCropperBanner";

interface UploadBannerProps {
  isOpen: boolean;
  onClose: () => void;
  setValue: any;
}

const UploadBannerModal: React.FC<UploadBannerProps> = ({
  isOpen,
  onClose,
  setValue,
}) => {
  return (
    <Modal
      open={isOpen}
      backdrop={false}
      className="flex flex-col justify-center items-center bg-white"
    >
      <Modal.Header className="">
        <div
          className="absolute right-4 top-4"
          onClick={onClose}
          style={{ cursor: "pointer" }}
        >
          ✕
        </div>
        <div className="font-bold mt-4 text-base">Upload Banner</div>
      </Modal.Header>
      <Modal.Body className="text-center text-sm">
        <ImageCropperBanner setValue={setValue} closeModal={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export default UploadBannerModal;
