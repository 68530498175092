import { DefaultAvatar, NotifSVG, SpixCommunityLogoGray } from "assets/images";
import SearchInput from "components/search-input";
import { HeaderName } from "data/header-name";
import { useState } from "react";
import { Avatar, Menu } from "react-daisyui";
import { FiSettings, FiUser } from "react-icons/fi";
import {
  IoChevronBackOutline,
  IoMenuOutline,
  IoArrowBack,
} from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";

interface HeaderProps {
  toggleSidebar: () => void;
  className?: string;
  toggleRightbar?: () => void;
}

const NotificationHeader: React.FC<HeaderProps> = ({
  toggleSidebar,
  className,
  toggleRightbar,
}): JSX.Element => {
  const navigate = useNavigate();

  return (
    <>
      <nav
        className={`lg:flex fixed z-[998] select-none h-[60px] bg-[#F6F6F6] py-4 pt-14 px-8 hidden top-0 justify-between items-center ${className}`}
      >
        <div className="p-2">
          <h1 className="text-2xl font-bold text-black">Notification</h1>
        </div>
        <button className="mr-4 text-xl" onClick={()=> navigate(-1)}>x</button>
      </nav>
      <nav
        className={`bg-white lg:hidden z-[998] flex flex-col select-none h-fit mb-2 top-0 ${className}`}
      >
        <div className="flex justify-between items-center">
          <div className="p-2">
            <img src={SpixCommunityLogoGray} width={150} />
          </div>
          <button className="mr-4 text-xl" onClick={()=> navigate(-1)}>x</button>
        </div>
        <div className="flex justify-between items-center bg-white p-2 w-full">
          <IoMenuOutline
            size={30}
            className="flex relative cursor-pointer"
            onClick={toggleSidebar}
          />
          <div className="p-2">
            <h1 className="text-base font-bold text-black">Notification</h1>
          </div>
          <div className="min-w-[30px]">
            <div
              className={`${
                toggleRightbar !== undefined ? "flex" : "hidden"
              } relative cursor-pointer`}
              onClick={toggleRightbar}
            >
              <IoChevronBackOutline
                size={20}
                className="absolute right-4"
                color="#ADADAD"
              />
              <IoChevronBackOutline size={20} color="#ADADAD" />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NotificationHeader;
