import {
  UpdateProfileForm,
  UpdateProfileReq,
} from "_interfaces/user.interface";
import useUpdateProfileForm from "hooks/profile/useUpdateProfileForm";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-daisyui";
import { useNavigate } from "react-router-dom";
import { useGetProfileQuery } from "services/modules/profile";
import { toast } from "react-toastify";
import usePdfPreview from "hooks/shared/usePdfPreview";
import UploadAvatarModal from "components/profile/UploadAvatarModal";
import useFilePreview from "hooks/shared/useFilePreview";
import useImgProfilePreview from "hooks/shared/useImgProfilePreview";
import { IoPerson } from "react-icons/io5";
import UploadBannerModal from "components/profile/UploadBannerModal";
import { MdEdit, MdDelete } from "react-icons/md";
import { DefaultAvatar } from "assets/images";
import { Loader } from "components/spinner/loader";

export const editProfileRouteName = "profile/edit";
const EditProfile: React.FC = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetProfileQuery();
  const { register, errors, watch, setValue, reset, handleUpdate } =
    useUpdateProfileForm(data?.data.id);
  const [randomColor, setRandomColor] = useState<string>("");

  const color = useMemo(() => {
    return [
      "#1A4D2E",
      "#68848B",
      "#161D6E",
      "#AC0C0C",
      "#2C3E50",
      "#7A3476",
      "#F06161",
      "#865439",
    ];
  }, []);

  useEffect(() => {
    if (data?.data) {
      let temp: UpdateProfileForm = {
        name: data?.data.name,
        gender: data?.data.gender,
        company_address: data?.data.company_address,
        about: data?.data.about,
        dob: new Date(data?.data.dob).toISOString().split("T")[0],
        avatar: {
          link: data.data.avatar ?? "",
        },
        banner: {
          link: data.data.banner ?? "",
        },
      };
      reset(temp);
      setRandomColor(color[Math.floor(Math.random() * color.length)]);
    }
  }, [data]);

  const [isUploadAvatarOpen, setIsUploadAvatarOpen] = useState(false);
  const avatar = watch("avatar");
  const avatarPreview = useImgProfilePreview(avatar.file);

  const [isUploadBannerOpen, setIsUploadBannerOpen] = useState(false);
  const banner = watch("banner");
  const bannerPreview = useImgProfilePreview(banner.file);

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <div>No data found.</div>;
  }

  return (
    <>
      <div className="w-full mx-auto p-5 bg-white rounded-xl shadow-md">
        <div className="relative w-full rounded-t-xl">
          {bannerPreview ? (
            <img
              src={bannerPreview}
              alt="Banner"
              className="w-full object-cover rounded-t-xl"
            />
          ) : banner.link ? (
            <img
              src={banner.link}
              className="w-full object-cover rounded-t-xl"
            />
          ) : (
            <div
              className="w-full object-cover rounded-t-xl min-h-[100px] lg:min-h-[250px]"
              style={{ backgroundColor: randomColor }}
            ></div>
          )}

          <div
            className="absolute right-5 top-5 flex gap-2"
            style={{ zIndex: 9999 }}
          >
            <button
              className="bg-white text-gray-800 p-2 rounded-full shadow-md w-8 h-8 flex items-center"
              onClick={() => {
                setIsUploadBannerOpen(true);
              }}
            >
              <MdEdit />
            </button>
            <button
              className="bg-white text-gray-800 p-2 rounded-full shadow-md w-8 h-8 flex items-center"
              onClick={() => {
                setValue("banner.file", undefined);
                setValue("banner.link", "");
              }}
            >
              <MdDelete />
            </button>
          </div>
        </div>
        <div className="relative -mt-28 lg:-mt-44 xl:-mt-56">
          <h3 className="text-xl font-semibold text-white p-4">
            Photo Profile
          </h3>
          <div className="flex items-center space-x-4 p-5 -mt-3">
            {avatarPreview ? (
              <img
                className="h-28 w-28 lg:h-32 lg:w-32 rounded-full border-4 border-white"
                src={avatarPreview}
                alt="Profile"
              />
            ) : avatar?.link ? (
              <img
                className="h-28 w-28 lg:h-32 lg:w-32 rounded-full border-4 border-white"
                src={avatar?.link}
                alt="Profile"
              />
            ) : (
              <img
                className="h-28 w-28 lg:h-32 lg:w-32 rounded-full border-4 border-white"
                src={DefaultAvatar}
                alt="Profile"
              />
            )}
            <div>
              <div className="mt-2 block lg:flex space-x-2">
                <button
                  type="button"
                  className="bg-white mb-4 lg:mb-0 px-3 py-1 rounded-lg shadow-md"
                  onClick={() => {
                    setIsUploadAvatarOpen(true);
                  }}
                >
                  Upload New Picture
                </button>
                <button
                  type="button"
                  className="bg-gray-200 px-3 py-1 rounded-lg shadow-md"
                  onClick={() => {
                    setValue("avatar.file", undefined);
                    setValue("avatar.link", "");
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <form>
          <div className="relative">
            <h3 className="text-lg font-medium mt-4">Personal Information</h3>
            <div className="mt-3 space-y-5">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Full Name
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
                  id="name"
                  {...register("name")}
                />
              </div>
              {errors.name && (
                <p className="text-red-400 italic text-sm">
                  {errors.name.message}
                </p>
              )}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Gender
                  </label>
                  <select
                    id="gender"
                    {...register("gender")}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
                  >
                    <option value="l">Male</option>
                    <option value="p">Female</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    id="dob"
                    {...register("dob")}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Bio
                </label>
                <textarea
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
                  rows={3}
                  id="about"
                  {...register("about")}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="flex mt-8 justify-end gap-4">
            <Button
              type="button"
              onClick={() => {
                handleUpdate();
              }}
              className="bg-san-juan text-white hover:bg-san-juan hover:text-white"
            >
              Save
            </Button>
            <Button
              className="bg-gray-300 hover:bg-gray-300 border-none"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>

      {isUploadAvatarOpen && (
        <UploadAvatarModal
          isOpen={isUploadAvatarOpen}
          onClose={() => setIsUploadAvatarOpen(false)}
          setValue={setValue}
        />
      )}

      {isUploadBannerOpen && (
        <UploadBannerModal
          isOpen={isUploadBannerOpen}
          onClose={() => setIsUploadBannerOpen(false)}
          setValue={setValue}
        />
      )}
    </>
  );
};

export default EditProfile;
