import { Card } from "@material-tailwind/react";
import { CreatePostForm, UpdatePostForm } from "_interfaces/post.interface";
import { DeleteIcon, Folder, ImageIcon, PDF } from "assets/images";
import PDFViewer from "components/post/PDFViewer";
import useCreatePostForm from "hooks/post/UseCreatePostForm";
import useFilePreview from "hooks/shared/useFilePreview";
import usePdfPreview from "hooks/shared/usePdfPreview";
import { useEffect, useRef, useState } from "react";
import { Button, FileInput } from "react-daisyui";
import { useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { errorHandler } from "services/errorHandler";

interface CreateProps {
  success: () => void;
}

const CreatePostPage: React.FC<CreateProps> = ({ success }) => {
  const [isImageUploadOpen, setIsImageUploadOpen] = useState(false);
  const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
  const [modalPDF, setModalPDF] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    register,
    errors,
    setFocus,
    isLoading,
    watch,
    handleCreate,
    control,
    reset,
    setValue,
    create,
    handleSubmit,
  } = useCreatePostForm();

  const { fields, remove, insert, append } = useFieldArray({
    control,
    name: "images",
  });

  const image = watch("images");

  const imagePreview = image?.map(({ file }) => {
    if (file) {
      return URL.createObjectURL(file);
    }
  });

  const file = watch("file");
  const filePreview = usePdfPreview(file?.file);

  const onSubmit = async (formData: CreatePostForm) => {
    try {
      await create(formData);
      reset();
      success();
      toast.success("Post successfully added");
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleFileClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const fileInput = document.getElementById(`fileInput`) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
      fileInput.onchange = (e) => {
        const files = (e.target as HTMLInputElement).files;

        if (files && files.length > 0) {
          setValue(`file.file`, files);
        }
      };
    }
  };

  const handleImgClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const imgInput = document.getElementById(`imgInput`) as HTMLInputElement;
    if (imgInput) {
      imgInput.click();
      imgInput.onchange = (e) => {
        const files = (e.target as HTMLInputElement).files;

        if (files && files.length > 0 && fields.length < 5) {
          Array.from(files).forEach((el, i) => {
            if (i < 4) {
              append({ file: el });
            } else {
              toast.error(`Maximum 4 image, ${el.name} not included`);
            }
          });
        } else {
          toast.error("Maximum 4 image");
        }
      };
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card placeholder={""} className="flex flex-col p-5 mb-3">
          <h3 className="text-md text-black font-bold mb-4">Create New Post</h3>
          <div className="rounded-md w-full mb-4 outline-none bg-[#F6F6F6]">
            <textarea
              placeholder="Type a text"
              className="rounded-md w-full px-3 py-2 outline-none resize-none bg-[#F6F6F6]"
              style={{ height: "auto", overflowY: "hidden" }}
              {...register("text")}
              onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                const textarea = e.target;
                textarea.style.height = "auto";
                textarea.style.height = `${textarea.scrollHeight}px`;
              }}
            />
            {filePreview && file.file && (
              <>
                <div className="flex justify-between items-center cursor-pointer border border-gray-400 m-4 p-2">
                  <div
                    className="flex items-center"
                    onClick={() => {
                      setModalPDF(true);
                    }}
                  >
                    <img src={PDF} alt="pdf" className="w-12 h-12" />
                    {file.file && <p>{file.file[0]?.name}</p>}
                  </div>
                  <div
                    className="rounded-full p-2 bg-gray-300"
                    onClick={() => {
                      setValue(`file`, { file: undefined, link: "" });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={DeleteIcon} alt="Delete" />
                  </div>
                </div>
                {modalPDF && (
                  <PDFViewer
                    file={filePreview}
                    isOpen={modalPDF}
                    onClose={() => setModalPDF(false)}
                  />
                )}
              </>
            )}

            <div className="w-full flex flex-wrap flex-row gap-4">
              {fields.map((item, i) => (
                <div key={i}>
                  <div>
                    {imagePreview[i] !== undefined && (
                      <div className="relative">
                        <div
                          className="absolute right-4 top-4 rounded-full p-2 bg-gray-300"
                          onClick={() => {
                            remove(i);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={DeleteIcon} alt="Delete" />
                        </div>
                        <img
                          src={imagePreview[i]}
                          alt={`Image ${i}`}
                          className="h-[160px] w-full object-center rounded-md"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex justify-left items-center">
              <div>
                <input
                  id="fileInput"
                  type="file"
                  accept="application/pdf"
                  style={{ display: "none" }}
                />
                <button onClick={(e) => handleFileClick(e)}>
                  <img src={Folder} className="mr-4" />
                </button>
              </div>
              <div>
                <button onClick={(e) => handleImgClick(e)}>
                  <img src={ImageIcon} />
                </button>
                <input
                  id={`imgInput`}
                  type="file"
                  accept="image/*"
                  multiple
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <Button
              type="submit"
              className="bg-san-juan hover:bg-san-juan text-white"
              loading={isLoading}
            >
              Publish
            </Button>
          </div>
          <div>
            {errors.text && (
              <p className="text-red-400 italic text-sm">
                {errors.text.message}
              </p>
            )}
            {/* {errors.images && errors.images.type === "fileSize" && (
              <p className="text-red-400 italic text-sm">
                {errors.images.message}
              </p>
            )}
            {errors.images && errors.images.type === "fileOrLink" && (
              <p className="text-red-400 italic text-sm">
                {errors.images.message}
              </p>
            )}
            {errors.file && errors.file.type === "fileSize" && (
              <p className="text-red-400 italic text-sm">
                {errors.file.message}
              </p>
            )}
            {errors.file && errors.file.type === "fileOrLink" && (
              <p className="text-red-400 italic text-sm">
                {errors.file.message}
              </p>
            )} */}
          </div>
        </Card>
      </form>
    </>
  );
};

export default CreatePostPage;
