import { Avatar } from "@mui/material";
import { CommentList } from "_interfaces/forum.interface";
import { DefaultAvatar, DeleteIcon } from "assets/images";
import DeletePopUp from "components/modal/other/Delete";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import { errorHandler } from "services/errorHandler";
import { useDeleteCommentMutation } from "services/modules/comment";
import { useAppSelector } from "store";

interface props {
  data: CommentList;
  refetch: () => void;
}

const CommentCard: React.FC<props> = ({ data, refetch }) => {
  const { user } = useAppSelector((state) => state.auth);
  const [selectedComment, setSelectedComment] = useState<string>("");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const handleDeletePopUp = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };
  const [deleteComment] = useDeleteCommentMutation();
  const handleDeleteComment = async (id: string): Promise<void> => {
    try {
      const statusUpdated = { id };
      await deleteComment(statusUpdated);
      refetch();
      toast.success("Comment successfully deleted");
    } catch (error) {
      errorHandler(error);
    }
  };
  return (
    <div className="flex flex-col w-full gap-3">
      <div className="flex justify-between items-center">
        {data.user !== null ? (
          <div className="flex items-center gap-2">
            <Avatar
              src={data?.user?.avatar ?? DefaultAvatar}
              alt="avatar"
              variant="circular"
            />
            <p className="text-base font-medium">{data.user.name}</p>
          </div>
        ) : (
          <p className="text-base font-medium">Admin</p>
        )}
        {data?.user?.id === user?.id && (
          <button
            onClick={() => {
              setSelectedComment(data?.id as string);
              handleDeletePopUp();
            }}
          >
            <img src={DeleteIcon} alt="Delete Icon" />
          </button>
        )}
        <div className="flex">
          <p>{moment(data.created_at).startOf("second").fromNow()}</p>
        </div>
      </div>
      <div className="bg-neutral-200 p-2 pb-4 w-full rounded-md">
        <p className="text-[#023047] font-medium">{data.text}</p>
      </div>
      <DeletePopUp
        isOpen={isDeletePopupOpen}
        data={"Comment"}
        onClose={handleDeletePopUp}
        onEdit={() => {
          handleDeletePopUp();
          void handleDeleteComment(selectedComment);
        }}
        menu="Comment"
      />
    </div>
  );
};

export default CommentCard;
