import { Dialog } from "@material-tailwind/react";
import React from "react";
import { Modal } from "react-daisyui";
import { MdCancel } from "react-icons/md";
interface ErrorPopupProps {
  isOpen: boolean;
  data: string | null;
  onClose: () => void;
}

const PopUpImage: React.FC<ErrorPopupProps> = ({ isOpen, data, onClose }) => {
  if (data === null) {
    return null;
  }
  return (
    <Dialog
      open={isOpen}
      placeholder={""}
      handler={() => {}}
      className="flex flex-col justify-center items-center bg-transparent shadow-none"
    >
      <div className="relative">
        <MdCancel
          color="white"
          onClick={onClose}
          size={30}
          className="absolute -right-4 top-4 cursor-pointer"
        />
        <img className="mt-8 min-w-[60vw] min-h-[60%]" src={data} alt="data" />
      </div>
    </Dialog>
  );
};

export default PopUpImage;
