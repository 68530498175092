import {
  BusinessInfo,
  CreateBusinessInfoForm,
  CreateBusinessInfoReq,
} from "_interfaces/user.interface";
import { ImageIcon } from "assets/images";
import CInput from "components/input";
import useUpdateBusinessInfoForm from "hooks/profile/useUpdateBusinessInfoForm";
import usePdfPreview from "hooks/shared/usePdfPreview";
import { useEffect, useState } from "react";
import { Button } from "react-daisyui";
import { toast } from "react-toastify";
import { errorHandler } from "services/errorHandler";
import { useGetBusinessInfoQuery } from "services/modules/profile";

export default function UpdateBusiness({
  business,
  onClose,
  onCloseSuccess,
}: {
  business: BusinessInfo;
  onClose: () => void;
  onCloseSuccess: () => void;
}): React.ReactElement {
  const [logo, setLogo] = useState<string>();
  const {
    register,
    errors,
    reset,
    setFocus,
    watch,
    handleSubmit,
    update,
    isLoading,
    control,
    handleUpdate,
    setValue,
  } = useUpdateBusinessInfoForm(business.id);

  const business_logo = watch("business_logo");
  const logoPreview = usePdfPreview(business_logo?.file);

  const handleFileClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const fileInput = document.getElementById(`fileInput`) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
      fileInput.onchange = (e) => {
        const files = (e.target as HTMLInputElement).files;

        if (files && files.length > 0) {
          setValue(`business_logo.file`, files);
        }
      };
    }
  };

  useEffect(() => {
    if (business) {
      let temp: CreateBusinessInfoForm = {
        business_name: business.business_name,
        business_sector: business.business_sector,
        business_scale: business.business_scale,
        company_address: business.company_address,
        business_logo: {
          link: business.business_logo ?? "",
        },
        description: business.description,
      };
      setLogo(business.business_logo);
      reset(temp);
    }
  }, [business]);

  const onSubmit = async () => {
    try {
      await handleUpdate();
      onCloseSuccess();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <form>
      <div className="w-full bg-white p-6 shadow-lg rounded-lg">
        <h5 className="text-md font-semibold mb-4">Business Information</h5>
        <div className="block lg:grid lg:grid-cols-2 gap-4 mb-4">
          <div className="mb-4 lg:mb-0">
            <label className="block text-sm font-medium text-gray-700">
              Business Name
            </label>
            <input
              type="text"
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              id="business_name"
              {...register("business_name")}
            />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-sm font-medium text-gray-700">
              Business Sector
            </label>
            <input
              type="text"
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              id="business_sector"
              {...register("business_sector")}
            />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-sm font-medium text-gray-700">
              Business Scale
            </label>
            <select
              id="business_scale"
              {...register("business_scale")}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            >
              <option value="small">Small</option>
              <option value="medium">Medium</option>
              <option value="medium">Large</option>
            </select>
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-sm font-medium text-gray-700">
              Company Address
            </label>
            <input
              type="text"
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              id="company_address"
              {...register("company_address")}
            />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-sm font-medium text-gray-700">
              Business Logo
            </label>
            <div className="mt-1 p-2 w-full border border-gray-300 rounded-md flex justify-between">
              {business_logo.file
                ? business_logo.file[0]?.name
                : logo
                ? logo.split("/")[logo.split("/").length - 1]
                : ""}
              <div className="ml-auto">
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                />
                <button onClick={(e) => handleFileClick(e)}>
                  <img src={ImageIcon} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            rows={3}
            id="description"
            {...register("description")}
          ></textarea>
        </div>
        {/* <h2 className="text-2xl font-semibold mb-4">Portfolio</h2>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Client
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Doing What
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Start Date
              </label>
              <input
                type="date"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                End Date
              </label>
              <input
                type="date"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              rows={3}
            ></textarea>
          </div> */}
        <div className="mt-4 flex justify-end gap-4">
          <Button
            type="button"
            className="bg-san-juan text-white hover:bg-san-juan hover:text-white"
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            Save
          </Button>
          <Button
            type="button"
            className="bg-gray-300 hover:bg-gray-300 border-none"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
}

