import { CreatePortfolioReq, Portfolio } from "_interfaces/user.interface";
import useUpdatePortfolioForm from "hooks/profile/useUpdatePortfolio";
import { useEffect, useState } from "react";
import { Button } from "react-daisyui";
import { Controller } from "react-hook-form";
import { errorHandler } from "services/errorHandler";

export default function UpdatePortfolio({
  portfolio,
  onClose,
  onCloseSuccess,
}: {
  portfolio: Portfolio;
  onClose: () => void;
  onCloseSuccess: () => void;
}): React.ReactElement {
  const {
    register,
    errors,
    reset,
    setFocus,
    handleSubmit,
    update,
    isLoading,
    handleUpdate,
    control,
  } = useUpdatePortfolioForm(portfolio.id);

  useEffect(() => {
    if (portfolio) {
      let temp: CreatePortfolioReq = {
        bussinesId: portfolio.bussinesId,
        client: portfolio.client,
        project: portfolio.project,
        address: portfolio.address,
        description: portfolio.description,
        start_date: new Date(portfolio.start_date).toISOString().split("T")[0],
      };

      if (portfolio.end_date) {
        temp.end_date = new Date(portfolio.end_date)
          .toISOString()
          .split("T")[0];
      }

      reset(temp);
    }
  }, [portfolio]);

  const onSubmit = async () => {
    try {
      await handleUpdate();
      onCloseSuccess();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full bg-white p-6 shadow-lg rounded-lg">
          <h5 className="text-md font-semibold mb-4">Update Portfolio</h5>
          <div className="block lg:grid lg:grid-cols-2 gap-4 mb-4">
            <div className="mb-4 lg:mb-0">
              <input
                type="hidden"
                defaultValue={portfolio.bussinesId as string}
                {...register("bussinesId")}
              />
              <label className="block text-sm font-medium text-gray-700">
                Client
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                {...register("client")}
              />
              <p className="text-red-500">{errors.client?.message}</p>
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                Project
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                {...register("project")}
              />
              <p className="text-red-500">{errors.project?.message}</p>
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                {...register("address")}
              />
              <p className="text-red-500">{errors.address?.message}</p>
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                Start Date
              </label>
              <input
                type="date"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                {...register("start_date")}
              />
              <p className="text-red-500">{errors.start_date?.message}</p>
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                End Date
              </label>
              <input
                type="date"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                {...register("end_date")}
              />
              <p className="text-red-500">{errors.end_date?.message}</p>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              rows={3}
              {...register("description")}
            ></textarea>
            <p className="text-red-500">{errors.description?.message}</p>
          </div>

          <div className="mt-6 flex justify-end gap-4">
            <Button
              type="submit"
              className="bg-san-juan text-white hover:bg-san-juan hover:text-white"
              loading={isLoading}
            >
              Save
            </Button>
            <Button
              type="button"
              className="bg-gray-300 hover:bg-gray-300 border-none"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
