import { useEffect, useState } from "react";
import { Menu, Button } from "react-daisyui";
import { Link, useNavigate } from "react-router-dom";
import {
  useNotificationQuery,
  useReadNotificationMutation,
} from "services/notification";
import {
  Notification as NotifType,
  NotificationListReq,
} from "_interfaces/notification.interface";

interface NotificationProps {
  isOpen: boolean;
}

const Notification: React.FC<NotificationProps> = ({ isOpen }): JSX.Element => {
  const navigation = useNavigate();
  const [params, setParams] = useState<NotificationListReq>({
    page: 1,
    limit: 10,
    search: "",
  });

  const { data, isLoading, refetch } = useNotificationQuery(params);

  const handleLoadMore = () => {
    setParams((prev) => ({ ...prev, limit: prev.limit + 10 }));
  };
  useEffect(() => {
    refetch();
  }, [isOpen]);

  return (
    <div className="z-[999] absolute top-[60px] right-0 bg-white w-80 rounded-lg shadow-lg min-h-[200px] max-h-[600px] overflow-y-auto">
      <div className="p-4 border-b flex justify-between items-center">
        <h2 className="font-bold text-lg">Notification</h2>
      </div>
      <Menu>
        {isLoading ? (
          <Menu.Item>
            <span>Loading...</span>
          </Menu.Item>
        ) : (
          data?.data.map((notification: NotifType, index: number) => (
            <Menu.Item
              key={index}
              onClick={() => {
                if (notification.title.includes("Event")) {
                  navigation(`/event/${notification.data.post_id}`);
                } else if (notification.data.post_id) {
                  navigation(`/post/${notification.data.post_id}`);
                }
              }}
            >
              <div className="flex items-center p-4 border-b border-gray-200">
                <div className="flex-shrink-0">
                  {!notification.read && (
                    <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                  )}
                </div>
                <div className="ml-3 flex justify-between w-full">
                  <p>{notification.title}</p>
                  {/* <img
                    src={notification.user.avatar || DefaultAvatar}
                    alt={`avatar`}
                    className="h-8 w-8 rounded-full"
                  /> */}
                </div>
              </div>
            </Menu.Item>
          ))
        )}
      </Menu>
      {data?.data && data?.data.length > params.limit && (
        <div className="p-4 flex justify-center">
          <button
            className="bg-transparent border-none underline cursor-pointer"
            onClick={handleLoadMore}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default Notification;
