import { EventListRes } from "_interfaces/event.interfaces";
import moment from "moment";
import { IoLocationOutline, IoPeopleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
interface props {
  data: EventListRes;
  type: "Upcoming" | "Ongoing";
}

const CardEvent: React.FC<props> = ({ data, type }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/event/${data.id}`);
  };
  if (type === "Ongoing") {
    return (
      <div
        className="flex flex-col bg-white w-full rounded-2xl overflow-hidden cursor-pointer"
        onClick={handleClick}
      >
        <img
          src={data.banner}
          alt="banner"
          className="w-full h-[130px] object-fill"
        />
        <div className="flex flex-col pb-10 p-4 gap-1">
          <p className="text-lg font-semibold text-black">{data.title}</p>
          <p className="text-sm font-medium text-[#333333]">
            {moment(data.date).format("dddd, D MMMM YYYY")}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div
      className="flex flex-col bg-white w-full rounded-2xl overflow-hidden cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex justify-start items-center gap-4 p-4">
        <img
          src={data.banner}
          alt="banner"
          className="h-[40px] w-[40px] object-fill rounded-full"
        />
        <div className="flex flex-col gap-1">
          <p className="text-lg font-semibold text-black">{data.title}</p>
          <p className="text-sm font-medium text-[#333333]">
            {moment(data.date).format("dddd, D MMMM YYYY")}
          </p>
        </div>
      </div>
      <div className="flex flex-col pb-10 p-4 gap-1">
        <div className="flex items-center gap-4">
          <IoLocationOutline size={20} />
          <p className="text-sm font-medium text-[#333333]">{data.location}</p>
        </div>
        <div className="flex items-center gap-4">
          <IoPeopleOutline size={20} />
          <p className="text-sm font-medium text-[#333333]">
            {data.description.length > 40
              ? data.description.slice(0, 35) + "..."
              : data.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardEvent;
