import DateCalendarValue from "components/date/Calendar";
import Header from "components/shared/header";
import Sidebar from "components/shared/sidebar";
import Container from "layout/container";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { FiX } from "react-icons/fi";
import {
  IoCalendarOutline,
  IoLocationOutline,
  IoTodayOutline,
} from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useDetailEventQuery } from "services/modules/event";
export const eventDetailRouteName = "event/:id";
const DetailEventCalendar = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [navbarActive, setNavbarActive] = useState<boolean>(false);
  const [rightbarActive, setRightbarActive] = useState<boolean>(false);
  const toggleSidebar = (): void => {
    setNavbarActive((prev) => !prev);
    setRightbarActive(false);
  };
  const toggleRightbar = (): void => {
    setRightbarActive((prev) => !prev);
    setNavbarActive(false);
  };
  const [value, setValue] = React.useState(moment(new Date()));
  const { data, isLoading } = useDetailEventQuery(id as string);
  return (
    <>
      <Helmet>
        <title>{t("Spix | Community")}</title>
      </Helmet>
      <div className="flex">
        <Sidebar active={navbarActive} toggleSidebar={toggleSidebar} />
        <Container className={`${"w-full lg:w-[60%] lg:relative"}`}>
          <Header
            className={`${"w-full lg:absolute"}`}
            toggleSidebar={toggleSidebar}
            toggleRightbar={toggleRightbar}
          />
          <div className="flex flex-col px-4 pt-5">
            <div className="flex justify-end pb-5">
              <FiX
                size={20}
                className="text-black cursor-pointer"
                onClick={() => {
                  navigate("/event");
                }}
              />
            </div>
            <div className="grid grid-cols-1">
              <div className="flex flex-col bg-white w-full rounded-2xl overflow-hidden">
                <img
                  src={data?.data.banner}
                  alt="banner"
                  className="w-full h-full object-fill"
                />
                <div className="flex flex-col pb-10 p-4 gap-1">
                  <div className="flex items-center gap-2">
                    <IoTodayOutline size={20} />
                    <p className="text-base font-bold text-[#333333]">
                      {data?.data.title}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <IoCalendarOutline size={20} />
                    <p className="text-sm font-medium text-[#333333]">
                      {moment(data?.data.date).format("dddd, D MMMM YYYY")}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <IoLocationOutline size={20} />
                    <p className="text-sm font-medium text-[#333333]">
                      {data?.data.location}
                    </p>
                  </div>
                  <div className="flex items-center pl-7">
                    <p className="text-sm font-medium text-[#333333]">
                      {data?.data.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="bg-white pt-4 hidden lg:block">
          <DateCalendarValue setValue={setValue} value={value} />
        </div>
        {rightbarActive ? (
          <>
            <div className="fixed z-[10] top-0 left-0 w-full h-full bg-black opacity-50 lg:hidden"></div>
            <div className="fixed left-6 z-50 top-6">
              <FiX
                size={30}
                className="z-10 cursor-pointer text-black"
                onClick={toggleRightbar}
              />
            </div>
          </>
        ) : null}
        <div
          className={`${
            rightbarActive ? "w-[80%] z-50" : "w-0"
          } fixed lg:hidden top-0 right-0 bg-white min-h-full backdrop-blur-xl`}
        >
          <DateCalendarValue setValue={setValue} value={value} />
        </div>
      </div>
    </>
  );
};
export default DetailEventCalendar;
