import { SpixCommunityLogo } from "assets/images";
import { Outlet } from "react-router-dom";
interface AuthLayoutProps {}
const AuthLayout: React.FC<AuthLayoutProps> = (): React.ReactElement => {
  return (
    <div className="flex justify-between w-screen bg-black">
      <div className="flex flex-col justify-center items-center bg-white w-full md:w-1/2 h-screen overflow-auto">
        <div className="bg-white w-full px-8 lg:px-[18%]">
          <Outlet />
        </div>
      </div>
      <div className="hidden md:flex flex-col justify-center items-center w-1/2 bg-spix-200 h-screen">
        <img src={SpixCommunityLogo} alt="spix" />
      </div>
    </div>
  );
};

export default AuthLayout;
