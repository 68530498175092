import { useEffect, useMemo, useState } from "react";
import { Button } from "react-daisyui";
import CIconInput from "components/input/CIconInput";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import useRegisterForm from "hooks/auth/useRegisterForm";
import ValidationError from "components/validation/error";
import { MdLockOutline } from "react-icons/md";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import Loading from "../../assets/animation/loading.json";
import Lottie from "lottie-react";
import { IoInformationCircle } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
const Register = () => {
  const [errorPassword, setErrorPassword] = useState<string>("");
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePassword2, setHidePassword2] = useState(true);
  const [searchParams] = useSearchParams();
  const registrationTypeParam = searchParams.get("registration-type");
  const registrationType = registrationTypeParam as
    | "BUSINESS"
    | "KOL"
    | "Verification"
    | "CP";
  const genderList = useMemo(
    () => [
      {
        key: 1,
        label: "Male",
        value: "l",
      },
      {
        key: 2,
        label: "Female",
        value: "p",
      },
    ],
    [],
  );
  const businessList = useMemo(
    () => [
      {
        key: 1,
        label: "Kecil",
        value: "small",
      },
      {
        key: 2,
        label: "Menengah",
        value: "medium",
      },
      {
        key: 3,
        label: "Besar",
        value: "large",
      },
    ],
    [],
  );
  const [type, setType] = useState<"BUSINESS" | "KOL" | "Verification" | "CP">(
    registrationType,
  );
  const { register, errors, control, handleRegister, isLoading, reset } =
    useRegisterForm({ setType, setErrorPassword, registrationType: type });
  useEffect(() => {
    reset((val) => ({ ...val, password: "" }));
  }, [type]);

  return (
    <div className={`flex flex-col ${type === "BUSINESS" ? "pt-20" : ""}`}>
      <div
        className={`flex flex-col gap-8 ${
          type === "BUSINESS" || type === "KOL" ? "mb-10" : ""
        }`}
      >
        <h1
          className={`text-5xl font-[700] text-[#1A354B] relative w-full ${
            type === "BUSINESS" ? "mt-20" : ""
          }`}
        >
          {type === "Verification"
            ? "Verification"
            : `Create ${type === "CP" ? "Password" : "an Account"}`}
        </h1>
        {(type === "Verification" || type === "CP") && (
          <small className="text-sm font-normal text-[#03014C]">
            {type === "CP"
              ? "Please enter your new password"
              : "Check your email to verify your account!"}
          </small>
        )}
      </div>
      {type !== "Verification" ? (
        <form
          onSubmit={handleRegister}
          className="font-poppins"
        >
          {type === "CP" ? (
            <div className="flex flex-col justify-around my-20 gap-4">
              <CIconInput
                register={register("password")}
                errors={errors.password}
                leftIcon={
                  <MdLockOutline
                    className={`w-6 h-6 cursor-pointer ${
                      errors.password ? "text-[#FF204E]" : "text-[#03014C]"
                    }`}
                  />
                }
                rightIconActive={
                  <EyeIcon
                    className={`w-6 h-6 cursor-pointer ${
                      errors.password ? "text-[#FF204E]" : "text-[#03014C]"
                    }`}
                    onClick={() => {
                      setHidePassword(false);
                    }}
                  />
                }
                rightIconInactive={
                  <EyeSlashIcon
                    className={`w-6 h-6 cursor-pointer ${
                      errors.password ? "text-[#FF204E]" : "text-[#03014C]"
                    }`}
                    onClick={() => {
                      setHidePassword(true);
                    }}
                  />
                }
                showRightIcon={hidePassword}
                type={hidePassword ? "password" : "text"}
                placeholder="New Password"
              />
              <CIconInput
                register={register("newPassword")}
                errors={errorPassword}
                leftIcon={
                  <MdLockOutline
                    className={`w-6 h-6 cursor-pointer ${
                      errorPassword ? "text-[#FF204E]" : "text-[#03014C]"
                    }`}
                  />
                }
                rightIconActive={
                  <EyeIcon
                    className={`w-6 h-6 cursor-pointer ${
                      errorPassword ? "text-[#FF204E]" : "text-[#03014C]"
                    }`}
                    onClick={() => {
                      setHidePassword2(false);
                    }}
                  />
                }
                rightIconInactive={
                  <EyeSlashIcon
                    className={`w-6 h-6 cursor-pointer ${
                      errorPassword ? "text-[#FF204E]" : "text-[#03014C]"
                    }`}
                    onClick={() => {
                      setHidePassword2(true);
                    }}
                  />
                }
                showRightIcon={hidePassword2}
                type={hidePassword2 ? "password" : "text"}
                placeholder="Confirm Password"
              />
              <div className="flex items-start gap-2">
                <IoInformationCircle
                  size={20}
                  className="text-[#03014C]"
                />
                <div className="flex flex-col">
                  <p className="text-xs text-[#03014C]">
                    Passwords must have at least 8 characters and contain at
                    least two of the following :
                  </p>
                  <p className="text-xs text-[#03014C]">
                    Uppercase letters, lowercase letters, numbers, and symbols.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-around w-full mb-10 gap-4">
              <CIconInput
                register={register("name")}
                errors={errors.name}
                type={"text"}
                placeholder="Full Name"
              />
              <Controller
                control={control}
                name="gender"
                render={({ field: { value, onChange } }) => (
                  <ReactSelect
                    styles={{
                      control: (baseStyle) => ({
                        ...baseStyle,
                        padding: 14,
                        borderColor: errors.gender ? "#FF204E" : "#03014C",
                        borderRadius: "0.5rem",
                        color: errors.gender ? "#FF204E" : "#03014C",
                      }),
                    }}
                    options={genderList}
                    placeholder={"Gender"}
                    isSearchable={true}
                    value={genderList.find((item) => {
                      return item.value === value;
                    })}
                    onChange={(e) => onChange(e?.value)}
                  />
                )}
              />
              <CIconInput
                register={register("birthdate")}
                errors={errors.birthdate}
                type={"date"}
                placeholder="Date of Birth"
              />
              <CIconInput
                register={register("email")}
                errors={errors.email}
                type={"email"}
                placeholder="Email"
              />
              <CIconInput
                register={register("phone_number")}
                errors={errors.phone_number}
                type="tel"
                placeholder="Phone Number"
              />
              <CIconInput
                register={register("business_name")}
                errors={errors.business_name}
                type={type === "BUSINESS" ? "text" : "number"}
                placeholder={
                  type === "BUSINESS" ? "Business Name" : "Followers"
                }
              />
              {type === "BUSINESS" && (
                <>
                  <Controller
                    control={control}
                    name="business_scale"
                    render={({ field: { value, onChange } }) => (
                      <ReactSelect
                        styles={{
                          control: (baseStyle) => ({
                            ...baseStyle,
                            padding: 14,
                            borderColor: "#03014C",
                            borderRadius: "0.5rem",
                            color: "#03014C",
                          }),
                        }}
                        options={businessList}
                        isSearchable={true}
                        placeholder={"Business Scale"}
                        value={businessList.find((item) => {
                          return item.value === value;
                        })}
                        onChange={(e) => onChange(e?.value)}
                      />
                    )}
                  />
                  <ValidationError error={errors.business_scale} />
                  <CIconInput
                    register={register("business_sector")}
                    errors={errors.business_sector}
                    type={"text"}
                    placeholder="Business Sector"
                  />
                </>
              )}
              <CIconInput
                register={register("company_address")}
                errors={errors.company_address}
                type={"text"}
                placeholder={
                  type === "BUSINESS" ? "Company Address" : "Address/City"
                }
              />
            </div>
          )}
          <Button
            type={"submit"}
            className="bg-[#173044] hover:bg-[#173044]/90 w-full text-white text-base font-semibold rounded-md py-6 items-center h-full shadow-xl"
            loading={isLoading}
          >
            Create
          </Button>
          {/* {(type === "KOL" || type === "BUSINESS") && (
            <section className="flex flex-row justify-center  w-full text-center my-5 gap-2">
              <p className="text-sm font-[700] text-[#03014C]">
                Not {type === "BUSINESS" ? "an" : "a"} {type}?{" "}
                <span
                  onClick={() => {
                    if (type === "BUSINESS") {
                      setType("KOL");
                    } else {
                      setType("BUSINESS");
                    }
                  }}
                  className="text-[#3B7EAD] cursor-pointer hover:text-[#3B7EAD]/80"
                >
                  click here for register as {type === "KOL" ? "UMKM" : "KOL"}
                </span>
              </p>
            </section>
          )} */}
        </form>
      ) : (
        <Lottie
          animationData={Loading}
          loop={true}
          width={500}
        />
      )}
    </div>
  );
};

export default Register;

