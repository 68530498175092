import { Forum } from "_interfaces/forum.interface";
import ForumCard from "components/forum/ForumCard";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useForumPostListQuery,
  useLikeForumMutation,
} from "services/modules/forum";
export const forumRouteName = "forum";
import Loading from "../../assets/animation/loading.json";
import Lottie from "lottie-react";

const ForumPage = () => {
  const navigate = useNavigate();
  const [forumData, setForumData] = useState<Forum[]>([]);
  const [params, setParams] = React.useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const { data, isLoading } = useForumPostListQuery(params);

  useEffect(() => {
    if (params.page === 1) {
      setForumData(data?.data as Forum[]);
    } else {
      setForumData((prev) => [...prev, ...(data?.data as Forum[])]);
    }
  }, [data]);

  const [likeForum] = useLikeForumMutation();
  return (
    <div className="flex flex-col px-4 pt-5">
      <div className="grid grid-cols-1 gap-4">
        {forumData?.map((el) => {
          return (
            <ForumCard
              data={el}
              key={el.id}
              navigateToDetail={() => {
                navigate(`/forum/${el.id}`);
              }}
              like={async () => {
                try {
                  const forumToUpdate = forumData.find(
                    (forum) => forum.id === el.id
                  );
                  if (!forumToUpdate) {
                    toast.error("Forum not found in the list");
                  }

                  const res = await likeForum({ forumId: el.id }).unwrap();
                  if (res) {
                    toast.success(res.message);
                    const updatedForumList = forumData.map((forum) => {
                      if (forum.id === el.id) {
                        const likes = forum.isLiked
                          ? forum.likes - 1
                          : forum.likes + 1;
                        return {
                          ...forum,
                          isLiked: !forum.isLiked,
                          likes,
                        };
                      }
                      return forum;
                    });

                    setForumData(updatedForumList);
                  }
                } catch (error) {
                  toast.error("error when like forum");
                }
              }}
            />
          );
        })}
      </div>
      {data?.data.length !== undefined && data?.meta?.hasNextPage && (
        <button
          onClick={() => {
            setParams((prev) => ({ ...prev, page: prev.page + 1 }));
          }}
        >
          load more
        </button>
      )}
      {isLoading && params.page > 1 && (
        <div className="flex justify-center">
          <Lottie
            animationData={Loading}
            loop={true}
            className="max-w-[100px]"
          />
        </div>
      )}
    </div>
  );
};
export default ForumPage;
