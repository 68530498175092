import React from "react";
import { Button, Modal } from "react-daisyui";
import ImageCropperAvatar from "./ImageCropperAvatar";

interface UploadAvatarProps {
  isOpen: boolean;
  onClose: () => void;
  setValue: any;
}

const UploadAvatarModal: React.FC<UploadAvatarProps> = ({
  isOpen,
  onClose,
  setValue,
}) => {
  return (
    <Modal
      open={isOpen}
      backdrop={false}
      className="flex flex-col justify-center items-center bg-white"
    >
      <Modal.Header className="flex flex-col justify-center items-center bg-white">
        <div
          className="absolute right-4"
          onClick={onClose}
          style={{ cursor: "pointer" }}
        >
          ✕
        </div>
        <div className="font-bold text-base">Upload Avatar</div>
      </Modal.Header>
      <Modal.Body className="text-center text-sm">
        <ImageCropperAvatar setValue={setValue} closeModal={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export default UploadAvatarModal;
