import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-daisyui";
import {
  useDeleteBusinessInfoMutation,
  useDeletePortfolioMutation,
  useGetBusinessInfoQuery,
  useGetPortfolioQuery,
} from "services/modules/profile";
import { IoAddCircleOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import CreateBusiness from "./create.pages";
import UpdateBusiness from "./update.pages";
import { BusinessInfo, Portfolio } from "_interfaces/user.interface";
import moment from "moment";
import CreatePortfolio from "./createPortfolio.pages";
import { toast } from "react-toastify";
import { errorHandler } from "services/errorHandler";
import DeletePopUp from "components/modal/other/Delete";
import { RiDeleteBinLine } from "react-icons/ri";
import UpdatePortfolio from "./updatePortfolio";
import { Loader } from "components/spinner/loader";
import Lottie from "lottie-react";
import addCompany from "../../../assets/animation/add-company.json";
import { useAppSelector } from "store";

export default function BusinessInfoPage(): React.ReactElement {
  const { user } = useAppSelector((state) => state.auth);
  const { data, isLoading, refetch } = useGetBusinessInfoQuery();
  const {
    data: portfolioData,
    isLoading: isLoadingPortfolio,
    refetch: refetchPortfolio,
  } = useGetPortfolioQuery();

  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(
    null,
  );

  const handleAccordionToggle = (index: number) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  const [openAccordionPortfolioIndex, setOpenAccordionPortfolioIndex] =
    useState<number | null>(null);

  const handleAccordionPortfolioToggle = (i: number) => {
    setOpenAccordionPortfolioIndex(
      openAccordionPortfolioIndex === i ? null : i,
    );
  };

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [isRefreshData, setIsRefreshData] = useState(false);
  const [isCreatePortfolioOpen, setIsCreatePortfolioOpen] = useState(false);

  const [selectedBusiness, setSelectedBusiness] = useState<BusinessInfo | null>(
    null,
  );

  useEffect(() => {
    if (data?.data) {
      const foundBusiness = data.data.find(
        (data: BusinessInfo) => data.id === selectedId,
      );
      setSelectedBusiness(foundBusiness || null);
    }
  }, [data, selectedId]);

  const [isEditPortfolioOpen, setIsEditPortfolioOpen] = useState(false);
  const [selectedIdPortfolio, setSelectedIdPortfolio] = useState<string>("");

  const [selectedPortfolio, setSelectedPortfolio] = useState<Portfolio | null>(
    null,
  );

  useEffect(() => {
    if (portfolioData?.data) {
      const foundPortfolio = portfolioData.data.find(
        (data: Portfolio) => data.id === selectedIdPortfolio,
      );
      setSelectedPortfolio(foundPortfolio || null);
    }
  }, [portfolioData, selectedIdPortfolio]);

  useEffect(() => {
    if (isRefreshData) {
      refetch();
      setIsRefreshData(false);
    }
  }, [isRefreshData]);

  const onClose = () => {
    setIsEditOpen(false);
    setIsCreateOpen(false);
    setIsCreatePortfolioOpen(false);
    setIsEditPortfolioOpen(false);
  };

  const onCloseSuccess = () => {
    setIsEditOpen(false);
    setIsCreateOpen(false);
    setIsCreatePortfolioOpen(false);
    setIsEditPortfolioOpen(false);
    refetch();
    refetchPortfolio();
  };

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [deleteBusinessInfo] = useDeleteBusinessInfoMutation();

  const handleDeletePopUp = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleDelete = async (id: string): Promise<void> => {
    try {
      const businessId = { id };
      await deleteBusinessInfo(businessId);
      refetch();
      toast.success("Business information successfully deleted");
    } catch (error) {
      errorHandler(error);
    }
  };

  const [isDeletePortfolioOpen, setIsDeletePortfolioOpen] = useState(false);
  const [deletePortfolio] = useDeletePortfolioMutation();

  const handleDeletePortfolioPopUp = () => {
    setIsDeletePortfolioOpen(!isDeletePortfolioOpen);
  };

  const handleDeletePortfolio = async (id: string): Promise<void> => {
    try {
      const portfolioId = { id };
      await deletePortfolio(portfolioId);
      refetchPortfolio();
      toast.success("Portfolio successfully deleted");
    } catch (error) {
      errorHandler(error);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <div>No data found.</div>;
  }

  return (
    <div>
      {isDeletePopupOpen && (
        <DeletePopUp
          isOpen={isDeletePopupOpen}
          data={"Business Information"}
          onClose={handleDeletePopUp}
          onEdit={() => {
            handleDeletePopUp();
            void handleDelete(selectedId);
          }}
          menu="Business Information"
        />
      )}

      {isDeletePortfolioOpen && (
        <DeletePopUp
          isOpen={isDeletePortfolioOpen}
          data={"Portfolio"}
          onClose={handleDeletePortfolioPopUp}
          onEdit={() => {
            handleDeletePortfolioPopUp();
            void handleDeletePortfolio(selectedIdPortfolio);
          }}
          menu="Portfolio"
        />
      )}

      {isCreateOpen && (
        <CreateBusiness
          onClose={onClose}
          onCloseSuccess={onCloseSuccess}
        />
      )}

      {isEditOpen && selectedBusiness && (
        <UpdateBusiness
          business={selectedBusiness}
          onClose={onClose}
          onCloseSuccess={onCloseSuccess}
        />
      )}

      {isCreatePortfolioOpen && selectedId && (
        <CreatePortfolio
          id={selectedId}
          onClose={onClose}
          onCloseSuccess={onCloseSuccess}
        />
      )}

      {isEditPortfolioOpen && selectedPortfolio && (
        <UpdatePortfolio
          portfolio={selectedPortfolio}
          onClose={onClose}
          onCloseSuccess={onCloseSuccess}
        />
      )}

      {isCreateOpen === false &&
        isEditOpen === false &&
        isCreatePortfolioOpen === false &&
        isEditPortfolioOpen === false && (
          <div>
            {data.data.length !== 0 ? (
              <div className="flex justify-end m-2">
                {user?.type !== "KOL" && (
                  <button onClick={() => setIsCreateOpen(true)}>
                    <IoAddCircleOutline size={26} />
                  </button>
                )}
              </div>
            ) : (
              <div className="flex justify-center m-2 w-full">
                <button
                  className="w-1/2"
                  onClick={() => setIsCreateOpen(true)}
                >
                  <Lottie
                    animationData={addCompany}
                    loop={true}
                    width={200}
                  />
                  <p className="text-xl text-spix text-center">
                    Add your business information
                  </p>
                </button>
              </div>
            )}
            {data?.data.map((data: any, index: any) => (
              <div
                key={index}
                className="collapse collapse-arrow w-full bg-white border border-md shadow mb-4"
              >
                <input
                  type="checkbox"
                  checked={openAccordionIndex === index}
                  onChange={() => handleAccordionToggle(index)}
                />
                {user?.type === "KOL" ? (
                  <div className="collapse-title text-md font-medium">
                    {data.business_sector} | {data.business_name} Followers
                  </div>
                ) : (
                  <div className="collapse-title text-md font-medium">
                    {data.business_name} | {data.business_sector}
                  </div>
                )}
                <div className="collapse-content">
                  {user?.type !== "KOL" ? (
                    <>
                      <div className="flex justify-between">
                        <h5 className="text-md font-semibold mb-4">
                          Business Information
                        </h5>
                        <div className="flex justify-end m-2 gap-4">
                          <button
                            onClick={() => {
                              setSelectedId(data.id as string);
                              setIsEditOpen(true);
                              setIsCreateOpen(false);
                            }}
                          >
                            <FiEdit size={20} />
                          </button>
                          <button
                            onClick={() => {
                              setSelectedId(data.id as string);
                              handleDeletePopUp();
                            }}
                          >
                            <RiDeleteBinLine size={20} />
                          </button>
                        </div>
                      </div>
                      <div className="block lg:flex">
                        <div className="lg:flex-1">
                          <div className="flex items-center mb-4">
                            {data.business_logo && (
                              <img
                                src={data.business_logo}
                                alt="Business Logo"
                                className="w-10 h-10 rounded-full mr-4"
                              />
                            )}
                            <div className="flex flex-col">
                              <span className="text-sm font-semibold">
                                Business Name
                              </span>
                              <span className="text-base">
                                {data.business_name}
                              </span>
                            </div>
                          </div>
                          <div className="mb-4">
                            <span className="text-sm font-semibold">
                              Business Scale
                            </span>
                            <p className="text-base">{data.business_scale}</p>
                          </div>
                          <div className="mb-4">
                            <span className="text-sm font-semibold">
                              Business Sector
                            </span>
                            <p className="text-base">{data.business_sector}</p>
                          </div>
                        </div>
                        <div className="flex-1 lg:ml-4">
                          <span className="text-sm font-semibold">
                            Description
                          </span>
                          <p className="text-base">{data.description}</p>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="flex justify-between">
                    <h5 className="text-md font-semibold my-4">Portfolio</h5>
                    <div className="flex">
                      <button
                        onClick={() => {
                          setSelectedId(data.id as string);
                          setIsCreatePortfolioOpen(true);
                          setIsEditOpen(false);
                          setIsCreateOpen(false);
                        }}
                      >
                        <IoAddCircleOutline size={26} />
                      </button>
                    </div>
                  </div>
                  {portfolioData?.data.map(
                    (portfolio: Portfolio, i: number) => (
                      <>
                        {portfolio.bussinesId === data.id && (
                          <div
                            key={i}
                            className="collapse collapse-arrow w-full bg-white border border-md shadow mb-4"
                          >
                            <input
                              type="checkbox"
                              checked={openAccordionPortfolioIndex === i}
                              onChange={() => handleAccordionPortfolioToggle(i)}
                            />
                            <div className="collapse-title text-md font-medium">
                              {portfolio.client} | {portfolio.project}
                            </div>
                            <div className="collapse-content">
                              <div className="block">
                                <div className="flex justify-between">
                                  <div className="mb-4">
                                    <span className="text-sm font-semibold mb-4">
                                      Address
                                    </span>
                                    <p className="text-base">
                                      {portfolio.address}
                                    </p>
                                  </div>
                                  <div className="flex justify-end m-2 gap-4">
                                    <button
                                      onClick={() => {
                                        setSelectedIdPortfolio(
                                          portfolio.id as string,
                                        );
                                        setIsEditPortfolioOpen(true);
                                        setIsCreatePortfolioOpen(false);
                                      }}
                                    >
                                      <FiEdit size={20} />
                                    </button>
                                    <button
                                      onClick={() => {
                                        setSelectedIdPortfolio(
                                          portfolio.id as string,
                                        );
                                        handleDeletePortfolioPopUp();
                                      }}
                                    >
                                      <RiDeleteBinLine size={20} />
                                    </button>
                                  </div>
                                </div>
                                <div className="lg:flex gap-4">
                                  <div className="flex-1 mb-4">
                                    <span className="text-sm font-semibold">
                                      Start Date
                                    </span>
                                    <p className="text-base">
                                      {moment(portfolio.start_date).format(
                                        "dddd, D MMMM YYYY",
                                      )}
                                    </p>
                                  </div>
                                  <div className="flex-1 mb-4">
                                    <span className="text-sm font-semibold">
                                      End Date
                                    </span>
                                    <p className="text-base">
                                      {portfolio.end_date &&
                                      portfolio.end_date !== null
                                        ? moment(portfolio.end_date).format(
                                            "dddd, D MMMM YYYY",
                                          )
                                        : "Present"}
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <span className="text-sm font-semibold">
                                    Description
                                  </span>
                                  <p className="text-base">
                                    {portfolio.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ),
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
    </div>
  );
}

