import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "store";
import { CreatePortfolioReq } from "_interfaces/user.interface";
import { useCreatePortfolioMutation } from "services/modules/profile";
import { toast } from "react-toastify";

const useCreatePortfolioForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [createPortfolio] = useCreatePortfolioMutation();
  const { accessToken } = useAppSelector((state) => state.auth);

  const schema = yup.object().shape({
    bussinesId: yup
    .string()
    .required("Business id cannot empty"),
    client: yup
    .string()
    .required("Client cannot empty"),
    project: yup
    .string()
    .required("Project cannot empty"),
    address: yup
    .string()
    .required("Address cannot empty"),
    description: yup
    .string()
    .required("Description cannot empty"),
    start_date: yup
    .string()
    .required("Start date cannot empty"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setFocus,
    watch,
    reset,
    setValue,
  } = useForm<CreatePortfolioReq>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const create = async (data: CreatePortfolioReq) => {
    try {
      setIsLoading(true);
      const payload: CreatePortfolioReq = {
        bussinesId: data.bussinesId,
        client: data.client,
        project: data.project,
        address: data.address,
        description: data.description,
        start_date: data.start_date,
        end_date: data.end_date !== "" ? data.end_date : null,
      };
      await createPortfolio(payload).unwrap();
      reset();
      toast.success("Portofolio successfully added");
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = handleSubmit(create);

  return {
    handleCreate,
    register,
    errors,
    setFocus,
    control,
    isLoading,
    watch,
    reset,
    setValue,
    handleSubmit,
    create,
  };
};

export default useCreatePortfolioForm;
