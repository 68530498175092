import { useEffect, useState } from "react";

const usePdfPreview = (file?: FileList) => {
  const [imgSrc, setImgSrc] = useState<string>();

  useEffect(() => {
    if (file && file[0]) {
      const newUrl = URL.createObjectURL(file[0]);
      setImgSrc(newUrl);
      return () => URL.revokeObjectURL(newUrl); 
    } else {
      setImgSrc(undefined); 
    }
  }, [file]);

  return imgSrc;
};

export default usePdfPreview;
