import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-daisyui";
import CInput from "components/input";
import { toast } from "react-toastify";
interface ShareLinkProps {
  open: boolean;
  onClose: () => void;
  url: string;
}

const ShareLink: React.FC<ShareLinkProps> = ({
  open,
  onClose,
  url
}) => {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(url).then(() => {
      toast(`URL copied to clipboard`);
    }).catch((error) => {
      toast.error("Error copying URL to clipboard");
    });
  };

  return (
    <div>
      <Modal
        backdrop={false}
        open={open}
        className="bg-white !w-4/6"
        responsive={true}
      >
          <Modal.Header>
            <h3 className="text-2xl text-[#262626] font-bold">Share</h3>
            <button className="absolute right-4 top-4" onClick={onClose}>✕</button>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-6">
              <CInput
                type="text"
                id="link"
                value={url}
                readOnly
              />
            </div>
          </Modal.Body>
          <Modal.Actions>
          <Button onClick={handleCopyLink} className="bg-san-juan text-white hover:text-white hover:bg-san-juan hover:opacity-90">Copy Link</Button>
          </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ShareLink;
