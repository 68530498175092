import {
  DefaultAvatar,
  NotifNormalSVG,
  NotifSVG,
  SpixCommunityLogoGray,
} from "assets/images";
import SearchInput from "components/search-input";
import { HeaderName } from "data/header-name";
import { useEffect, useState } from "react";
import { Avatar, Menu } from "react-daisyui";
import { FiSettings, FiUser } from "react-icons/fi";
import { IoChevronBackOutline, IoMenuOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Notification from "components/post/Notification";
import { useGetProfileQuery } from "services/modules/profile";
import {
  useNotificationCountQuery,
  useReadNotificationMutation,
} from "services/notification";
import DownShift from "downshift";
import { useLazyActiveUserQuery } from "services/modules/auth";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
interface HeaderProps {
  toggleSidebar: () => void;
  className?: string;
  toggleRightbar?: () => void;
}

interface HeaderMenuProps {}

const HeaderMenu: React.FC<HeaderMenuProps> = (): JSX.Element => {
  return (
    <Menu className="z-[999] absolute top-[60px] right-0 bg-white w-56">
      <Menu.Item>
        <Link to="#" className="flex items-center gap-2">
          <FiUser size={20} />
          <span className="font-bold">Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="#" className="flex items-center gap-2">
          <FiSettings size={20} />
          <span className="font-bold">Settings</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

const DownShiftComponent = ({ getItemProps, inputValue }: any) => {
  const [getActiveUser, { data }] = useLazyActiveUserQuery();
  const handleSearch = () => {
    getActiveUser({
      page: 1,
      limit: 10,
      search: inputValue,
    });
  };
  const navigate = useNavigate();

  useEffect(() => {
    const debounce = setTimeout(() => {
      handleSearch();
    }, 500);
    return () => {
      debounce;
    };
  }, [inputValue]);

  return (
    <div className="absolute bg-white min-w-[200px] max-h-[300px] overflow-x-scroll rounded-lg p-4 flex flex-col gap-3 z-50">
      {data?.data.data.length === 0 && (
        <div className="text-center text-xs">No user found</div>
      )}
      {data?.data.data
        .filter(
          (i) =>
            !inputValue ||
            i.name.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((item) => (
          <div
            {...getItemProps({ item: item.name })}
            key={item.id}
            className="flex flex-row gap-2 items-center cursor-pointer"
            onClick={() => navigate(`/profile/${item.id}`)}
          >
            <img
              src={item.avatar ?? DefaultAvatar}
              alt={item.name}
              className="w-10 h-10 rounded-full"
            />
            <div>
              <div className="text-base">{item.name}</div>
              <div className="text-xs text-gray-400">{item.email}</div>
            </div>
          </div>
        ))}
    </div>
  );
};

export const SearchBox = () => {
  return (
    <DownShift>
      {({
        getInputProps, // Props to pass to our input
        getItemProps, // Props to pass into each of the suggested items
        isOpen, // Whether the "suggestions box" is visible or not
        inputValue, // Value that the user typed in the search box
        selectedItem, // Item that is currently selected in the list (when hovering)
        highlightedIndex, // Index of the item currently selected in the list
      }: any) => (
        <div className="relative">
          <div className="flex border md:rounded-md items-center p-2 gap-3 bg-neutral-200 md:w-full w-[150px] rounded-full">
            <MagnifyingGlassIcon className="w-4 h-4 text-[#262626]" />
            <input
              type="text"
              className="outline-none bg-neutral-200 placeholder:text-[#727272] md:w-full w-[100px]"
              {...getInputProps({
                placeholder: "Search",
              })}
            />
          </div>
          {isOpen ? (
            <DownShiftComponent
              getItemProps={getItemProps}
              inputValue={inputValue}
              selectedItem={selectedItem}
              highlightedIndex={highlightedIndex}
            />
          ) : null}
        </div>
      )}
    </DownShift>
  );
};

const Header: React.FC<HeaderProps> = ({
  toggleSidebar,
  className,
  toggleRightbar,
}): JSX.Element => {
  const location = useLocation();
  const path = location.pathname;
  const [toolbarMenuVisible, setToolbarMenuVisible] = useState<boolean>(false);
  const toggleToolbarMenuVisible = () => setToolbarMenuVisible((prev) => !prev);
  const { data } = useGetProfileQuery();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    promo_code: "",
  });
  const Header = HeaderName.find((item) => {
    return path.slice(1).includes(item.path);
  });
  const [markAllAsReadMutation] = useReadNotificationMutation();
  const { data: NotifCount, refetch } = useNotificationCountQuery();

  const [notificationVisible, setNotificationVisible] =
    useState<boolean>(false);
  const toggleNotificationVisible = () =>
    setNotificationVisible((prev) => !prev);
  const navigate = useNavigate();

  return (
    <>
      <nav
        className={`lg:flex fixed z-[998] select-none h-[60px] bg-[#F6F6F6] py-4 pt-14 px-8 hidden top-0 justify-between items-center ${className}`}
      >
        <div className="p-2">
          <h1 className="text-2xl font-bold text-black">{Header?.title}</h1>
        </div>
        <div className="flex items-center gap-4 p-2 relative">
          <SearchBox />
          {NotifCount?.data !== 0 ? (
            <img
              src={NotifSVG}
              alt="notification"
              className="w-6 h-6 cursor-pointer"
              onClick={async () => {
                await markAllAsReadMutation();
                await refetch();
                toggleNotificationVisible();
              }}
            />
          ) : (
            <img
              src={NotifNormalSVG}
              alt="notification"
              className="w-6 h-6 cursor-pointer"
              onClick={toggleNotificationVisible}
            />
          )}
          {notificationVisible && <Notification isOpen={notificationVisible} />}
          <Avatar
            size="xs"
            shape="circle"
            src={data?.data.avatar ? data.data.avatar : DefaultAvatar}
          />
          {toolbarMenuVisible && <HeaderMenu />}
        </div>
      </nav>
      <nav
        className={`bg-white lg:hidden z-[998] flex flex-col select-none h-fit mb-2 top-0 ${className}`}
      >
        <div className="flex justify-between items-center">
          <div className="p-2">
            <img src={SpixCommunityLogoGray} width={150} />
          </div>
          <div className="flex items-center gap-4 p-2 relative">
            <SearchBox />
            {NotifCount?.data !== 0 ? (
              <img
                src={NotifSVG}
                alt="notification"
                className="w-6 h-6 cursor-pointer"
                onClick={async () => {
                  await markAllAsReadMutation();
                  await refetch();
                  navigate("/notification");
                }}
              />
            ) : (
              <img
                src={NotifNormalSVG}
                alt="notification"
                className="w-6 h-6 cursor-pointer"
                onClick={() => {
                  navigate("/notification");
                }}
              />
            )}

            <Avatar
              onClick={toggleToolbarMenuVisible}
              className="cursor-pointer"
              size="xs"
              shape="circle"
              src={data?.data.avatar ? data.data.avatar : DefaultAvatar}
            />
            {toolbarMenuVisible && <HeaderMenu />}
          </div>
        </div>
        <div className="flex justify-between items-center bg-white p-2 w-full">
          <IoMenuOutline
            size={30}
            className="flex relative cursor-pointer"
            onClick={toggleSidebar}
          />
          <div className="p-2">
            <h1 className="text-base font-bold text-black">{Header?.title}</h1>
          </div>
          <div className="min-w-[30px]">
            <div
              className={`${
                toggleRightbar !== undefined ? "flex" : "hidden"
              } relative cursor-pointer`}
              onClick={toggleRightbar}
            >
              <IoChevronBackOutline
                size={20}
                className="absolute right-4"
                color="#ADADAD"
              />
              <IoChevronBackOutline size={20} color="#ADADAD" />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
