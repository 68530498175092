import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "store";
import { uploadFile } from "services/modules/file";
import { CreateBusinessInfoForm, CreateBusinessInfoReq } from "_interfaces/user.interface";
import { useCreateBusinessInfoMutation } from "services/modules/profile";
import { toast } from "react-toastify";

const useCreateBusinessInfoForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [createBusinessInfo] = useCreateBusinessInfoMutation();
  const { accessToken } = useAppSelector((state) => state.auth);

  const schema = yup.object().shape({
    business_name: yup
    .string()
    .required("Business name cannot empty"),
    business_sector: yup
    .string()
    .required("Business sector cannot empty"),
    business_scale: yup
    .string()
    .required("Business scale cannot empty"),
    company_address: yup
    .string()
    .required("Company address cannot empty"),
    description: yup
    .string()
    .required("Description cannot empty"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setFocus,
    watch,
    reset,
    setValue,
  } = useForm<CreateBusinessInfoForm>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
        business_logo: { link: "" },
    },
  });

  const create = async (data: CreateBusinessInfoForm) => {
    try {
      setIsLoading(true);
      const payload: CreateBusinessInfoReq = {
        business_name: data.business_name,
        business_sector: data.business_sector,
        business_scale: data.business_scale,
        company_address: data.company_address,
        business_logo: data.business_logo.link!,
        description: data.description,
      };
      if (data.business_logo.file && data.business_logo.file[0]) {
        const file = await uploadFile(accessToken!, data.business_logo.file[0] as File);
        payload.business_logo = file;
      }
      const res = await createBusinessInfo(payload).unwrap();
      reset();
      toast.success("Business information successfully added");

      return res;
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = handleSubmit(create);

  return {
    handleCreate,
    register,
    errors,
    setFocus,
    control,
    isLoading,
    watch,
    reset,
    setValue,
    handleSubmit,
    create,
  };
};

export default useCreateBusinessInfoForm;
