import React, { useEffect, useState } from "react";
import { Card, Avatar } from "@material-tailwind/react";
import moment from "moment";
import { useLikePostMutation, usePostDetailQuery } from "services/modules/post";
import { useParams } from "react-router-dom";
import { Button } from "react-daisyui";
import PDFViewer from "./PDFViewer";
import {
  CommentIcon,
  Share,
  Liked,
  Like,
  PDF,
  DefaultAvatar,
} from "assets/images";
import ShareLink from "./ShareLink";
import { errorHandler } from "services/errorHandler";
import PopUpImage from "components/modal/banner/PopUpImage";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";

export default function DetailPostCard(): React.ReactElement {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, refetch } = usePostDetailQuery({ id: id as string });
  const user = useAppSelector((state) => state.auth.user);

  const [isSharePopUp, setIsSharePopupOpen] = useState(false);
  const [isImagePopUp, setIsImagePopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [likePost] = useLikePostMutation();

  const [liked, setLiked] = useState<boolean>(false);
  const [likes, setLikes] = useState<number>(0);

  useEffect(() => {
    if (data) {
      setLiked(data.isLiked);
      setLikes(data.data.likes);
    }
  }, [data]);

  const handleLike = async (postId: string): Promise<void> => {
    try {
      if (!data) {
        throw new Error("Post data not available");
      }

      const updatedLikes = liked ? likes - 1 : likes + 1;
      setLikes(updatedLikes);
      setLiked(!liked);

      await likePost({ postId });
    } catch (error) {
      errorHandler(error);
    }
  };

  const [file, setFile] = useState("");
  const [modalPDF, setModalPDF] = useState<boolean>(false);

  const isValidUrl = (url: string): boolean => {
    try {
      if (url) {
        new URL(url);
        return true;
      } else {
        return false;
      }
    } catch (_) {
      return false;
    }
  };

  const gridStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "16px",
    marginTop: "16px",
  };

  return (
    <>
      <ShareLink
        open={isSharePopUp}
        onClose={() => setIsSharePopupOpen(false)}
        url={window.location.href}
      />
      {selectedImage !== "" && (
        <PopUpImage
          isOpen={isImagePopUp}
          data={selectedImage}
          onClose={() => {
            setIsImagePopupOpen(false);
            setSelectedImage("");
          }}
        />
      )}
      {data?.data.system ? (
        <div className="flex flex-row gap-2 items-center justify-center">
          <div className="w-full h-0.5 bg-blue-gray-500" />
          <div className="w-full flex-grow text-center flex flex-col items-center justify-center">
            <p
              className="font-semibold text-xs cursor-pointer"
              onClick={() => {
                if (
                  data.data?.user?.id !== user?.id &&
                  data.data?.user?.id !== undefined
                ) {
                  navigate(`/profile/${data.data.user?.id}`);
                } else if (data.data?.user?.id === user?.id) {
                  navigate(`/profile`);
                }
              }}
            >
              {data.data.user?.name}
            </p>
            <p className="text-xs">
              {moment(data?.data.created_at).fromNow()} has joined
            </p>
          </div>
          <div className="w-full h-0.5 bg-blue-gray-500" />
        </div>
      ) : (
        <div className="flex gap-4 mb-4">
          <div
            className="flex gap-4"
            onClick={() => {
              navigate(`/profile/${data?.data.user?.id}`);
            }}
          >
            {data?.data?.user?.avatar ? (
              <Avatar
                placeholder={""}
                src={data?.data?.user?.avatar}
                alt="avatar"
                variant="circular"
                className="cursor-pointer"
              />
            ) : (
              <Avatar
                placeholder={""}
                src={DefaultAvatar}
                alt="avatar"
                variant="circular"
                className="cursor-pointer"
              />
            )}
            <div className="flex flex-col">
              <p className="text-lg font-semibold text-black cursor-pointer">
                {data?.data.by_admin ? "Admin" : data?.data.user?.name}
              </p>
              <p className="text-sm font-medium">
                {moment(data?.data.created_at).fromNow()}
              </p>
            </div>
          </div>
        </div>
      )}
      {(data?.data.images?.length as number) > 0 && (
        <div className="w-full grid grid-cols-2 gap-2">
          {data?.data.images.map((el: string, i: number) => {
            if (!isValidUrl(el)) {
              return null;
            }
            return (
              <img
                key={i}
                src={el}
                onClick={() => {
                  setSelectedImage(el);
                  setIsImagePopupOpen(true);
                }}
                style={{ cursor: "pointer" }}
                className={`rounded-xl object-cover w-full h-[200px] flex flex-col text-center items-center justify-center ${
                  data?.data.images?.length === 1 && i === 0
                    ? "col-span-2"
                    : "col-span-1"
                }`}
              />
            );
          })}
        </div>
      )}
      <div className="flex text-sm font-medium mt-4">{data?.data.text}</div>
      <div className="flex justify-left mt-4">
        <button
          className="flex items-center gap-1"
          onClick={() => handleLike(id as string)}
        >
          <img
            className={`transition-transform ${
              data?.isLiked || liked ? "scale-125" : ""
            }`}
            src={data?.isLiked || liked ? Liked : Like}
            alt={data?.isLiked || liked ? "Liked Icon" : "Like Icon"}
          />
          <span>{data?.isLiked ? data?.data.likes + 1 : data?.data.likes}</span>
        </button>
        <button className="ml-4">
          <img src={CommentIcon} alt="Comment Icon" />
        </button>
        <button className="ml-4" onClick={() => setIsSharePopupOpen(true)}>
          <img src={Share} alt="Share Icon" />
        </button>
        <div className="flex items-right">
          {isValidUrl(data?.data.file || "") && (
            <button
              onClick={() => {
                setModalPDF(true);
                setFile(data?.data.file as string);
              }}
              className="hover:bg-transparent border-none text-center ml-4"
            >
              <img src={PDF} alt="pdf" className="w-7 h-7" />
            </button>
          )}
          {modalPDF && (
            <PDFViewer
              file={file}
              isOpen={modalPDF}
              onClose={() => setModalPDF(false)}
            />
          )}
        </div>
      </div>
    </>
  );
}
