import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Sidebar from "components/shared/sidebar";
import Container from "layout/container";
import "react-datetime/css/react-datetime.css";
import NotificationHeader from "./Header";
import {
  NotificationListReq,
  Notification,
} from "_interfaces/notification.interface";
import {
  useNotificationQuery,
  useReadNotificationMutation,
} from "services/notification";
import { DefaultAvatar } from "assets/images";

export const notificationRouteName = "/notification";
const NotificationPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [navbarActive, setNavbarActive] = useState<boolean>(false);

  const toggleSidebar = (): void => {
    setNavbarActive((prev) => !prev);
  };

  const [params, setParams] = useState<NotificationListReq>({
    page: 1,
    limit: 10,
    search: "",
  });

  const { data, isLoading } = useNotificationQuery(params);

  const handleLoadMore = () => {
    setParams((prev) => ({ ...prev, limit: prev.limit + 10 }));
  };

  return (
    <>
      <Helmet>
        <title>{t("Spix | Community")}</title>
      </Helmet>
      <div className="block lg:flex lg:flex-row">
        <Sidebar active={navbarActive} toggleSidebar={toggleSidebar} />
        <Container className={`${"w-full lg:w-[80%] lg:relative"}`}>
          <NotificationHeader
            className={`${"w-full lg:absolute"}`}
            toggleSidebar={toggleSidebar}
          />
          {isLoading ? (
            <span>Loading...</span>
          ) : (
            data?.data.map((notification: Notification, index: number) => (
              <>
                <div className="flex items-center p-4 border-b border-gray-200">
                  <div className="flex-shrink-0">
                    {!notification.read && (
                      <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                    )}
                  </div>
                  <div className="ml-3 flex justify-between w-full">
                    <p>{notification.title}</p>

                    <img
                      src={DefaultAvatar}
                      alt={`avatar`}
                      className="h-8 w-8 rounded-full"
                    />
                  </div>
                </div>
              </>
            ))
          )}

          {data?.data && data?.data.length > params.limit && (
            <div className="p-4 flex justify-center">
              <button
                className="bg-transparent border-none underline cursor-pointer"
                onClick={handleLoadMore}
              >
                Load More
              </button>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default NotificationPage;
