import {
  CreateBusinessInfoForm,
  CreateBusinessInfoReq,
  CreatePortfolioReq,
} from "_interfaces/user.interface";
import { ImageIcon } from "assets/images";
import useCreateBusinessInfoForm from "hooks/profile/useCreateBusinessInfoForm";
import useCreatePortfolioForm from "hooks/profile/useCreatePortfolioForm";
import usePdfPreview from "hooks/shared/usePdfPreview";
import { useState } from "react";
import { Button } from "react-daisyui";
import { toast } from "react-toastify";
import { errorHandler } from "services/errorHandler";

export default function CreateBusiness({
  onClose,
  onCloseSuccess,
}: {
  onClose: () => void;
  onCloseSuccess: () => void;
}): React.ReactElement {
  const {
    register,
    errors,
    reset,
    setFocus,
    watch,
    handleSubmit,
    create,
    isLoading,
    control,
    handleCreate,
    setValue,
  } = useCreateBusinessInfoForm();

  const business_logo = watch("business_logo");
  const logoPreview = usePdfPreview(business_logo?.file);

  const handleFileClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const fileInput = document.getElementById(`fileInput`) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
      fileInput.onchange = (e) => {
        const files = (e.target as HTMLInputElement).files;

        if (files && files.length > 0) {
          setValue(`business_logo.file`, files);
        }
      };
    }
  };

  const onSubmit = async () => {
    try {
      await handleCreate();
      onCloseSuccess();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full bg-white p-6 shadow-lg rounded-lg">
          <h5 className="text-md font-semibold mb-4">Business Information</h5>
          <div className="block lg:grid lg:grid-cols-2 gap-4 mb-4">
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                Business Name
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                id="business_name"
                {...register("business_name")}
              />
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                Business Sector
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                id="business_sector"
                {...register("business_sector")}
              />
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                Business Scale
              </label>
              <select
                id="business_scale"
                {...register("business_scale")}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              >
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="medium">Large</option>
              </select>
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                Company Address
              </label>
              <input
                type="text"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                id="company_address"
                {...register("company_address")}
              />
            </div>
            <div className="mb-4 lg:mb-0">
              <label className="block text-sm font-medium text-gray-700">
                Business Logo
              </label>
              <div className="mt-1 p-2 block w-full border border-gray-300 rounded-md flex justify-between">
                {business_logo.file ? business_logo.file[0]?.name : ""}
                <div className="ml-auto">
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <button onClick={(e) => handleFileClick(e)}>
                    <img src={ImageIcon} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
              rows={3}
              id="description"
              {...register("description")}
            ></textarea>
          </div>
          <div className="mt-4 flex justify-end gap-4">
            <Button
              type="submit"
              className="bg-san-juan text-white hover:bg-san-juan hover:text-white"
              loading={isLoading}
            >
              Save
            </Button>
            <Button
              className="bg-gray-300 hover:bg-gray-300 border-none"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
