import { useEffect, useMemo, useState } from "react";
import PostInfo from "./post/index.pages";
import { useLocation, useNavigate } from "react-router-dom";
import { PostListReq } from "_interfaces/post.interface";
import {
  useGetOtherProfileQuery,
  useGetProfileQuery,
} from "services/modules/profile";
import BusinessInfoPage from "./business/index.pages";
import { IoPerson } from "react-icons/io5";
import PostInfoFriend from "./post/postFriend.pages";
import BusinessInfoFriend from "./business/businessInfoFriend";
import { useParams } from "react-router-dom";
import { DefaultAvatar } from "assets/images";
import { Loader } from "components/spinner/loader";

export const otherProfileRouteName = "profile/:id";

const calculateAge = (dob: string): number => {
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export default function OtherProfile(): React.ReactElement {
  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const location = useLocation();
  const [randomColor, setRandomColor] = useState<string>("");

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const color = useMemo(() => {
    return [
      "#1A4D2E",
      "#68848B",
      "#161D6E",
      "#AC0C0C",
      "#2C3E50",
      "#7A3476",
      "#F06161",
      "#865439",
    ];
  }, []);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState<PostListReq>({
    search: "",
    limit: 10,
    page: 1,
  });

  const { data, isLoading, refetch } = useGetOtherProfileQuery({
    id: id as string,
  });

  const dob = data?.data.dob ?? "";
  const age = dob ? calculateAge(dob) : "-";

  useEffect(() => {
    refetch();
  }, [location.key]);

  useEffect(() => {
    setRandomColor(color[Math.floor(Math.random() * color.length)]);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <div>No data found.</div>;
  }

  return (
    <>
      <div className="w-full mx-auto p-5 bg-white rounded-xl shadow-md">
        <div className="relative rounded-t-xl">
          {data?.data.banner ? (
            <img
              src={data?.data.banner}
              alt="Banner"
              className="w-full object-cover rounded-t-xl"
            />
          ) : (
            <div
              className="w-full object-cover rounded-t-xl min-h-[100px] lg:min-h-[250px]"
              style={{ backgroundColor: randomColor }}
            ></div>
          )}
        </div>
        <div className="relative -mt-20 lg:-mt-28 flex items-center justify-center lg:justify-start">
          <div className="block lg:flex items-center space-x-4 p-5">
            <div className="flex justify-center">
              {data?.data.avatar ? (
                <img
                  className="h-28 w-28 lg:h-32 lg:w-32 rounded-full border-4 border-white"
                  src={data?.data.avatar}
                  alt="Profile"
                />
              ) : (
                <img
                  className="h-28 w-28 lg:h-32 lg:w-32 rounded-full border-4 border-white"
                  src={DefaultAvatar}
                  alt="Profile"
                />
              )}
            </div>
            <h2 className="text-xl lg:text-2xl font-semibold text-black lg:text-white">
              {data?.data.name}
            </h2>
          </div>
        </div>
        <div className="block lg:flex lg:flex-row lg:gap-10">
          <div className="w-full lg:w-[30%] lg:relative flex justify-between">
            <div>
              <p className="mb-2">
                <strong>Score: {data?.data.score ?? 0} Point</strong>
              </p>
              <p>
                <strong>About Me:</strong> {data?.data.about ?? "-"}
              </p>
              <p>
                <strong>Age:</strong> {age}
              </p>
              <p>
                <strong>Gender:</strong>{" "}
                {data?.data.gender === "l" ? "Male" : "Female"}
              </p>
            </div>
          </div>
          <div className="w-full lg:w-[70%] lg:relative">
            <div className="flex">
              <div
                className={`py-2 px-6 cursor-pointer ${
                  tabIndex === 0 ? "border-b-2 border-san-juan" : ""
                }`}
                onClick={() => handleTabChange(0)}
              >
                Post
              </div>
              <div
                className={`py-2 px-6 cursor-pointer ${
                  tabIndex === 1 ? "border-b-2 border-san-juan" : ""
                }`}
                onClick={() => handleTabChange(1)}
              >
                Business
              </div>
            </div>
            <div>
              {tabIndex === 0 && <PostInfoFriend id={id as string} />}
              {tabIndex === 1 && (
                <BusinessInfoFriend id={id as string} userData={data.data} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
