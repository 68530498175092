import {
    CommentListReq,
    CommentListRes,
    CreateCommentForm,
  } from "_interfaces/comment.interface";
  import { Api } from "services/api";
  
  export const postApi = Api.injectEndpoints({
    endpoints: (build) => ({
      CommentList: build.query<CommentListRes, CommentListReq>({
        query: (param) =>
          `post/comment/member/${param.id}?limit=${param.limit}&page=${param.page}&search=${param.search}`,
        keepUnusedDataFor: 0,
      }),
      DeleteComment: build.mutation<string, { id: string }>({
        query(body) {
          return {
            url: `post/comment/member/${body.id}`,
            method: "DELETE",
            body: {
              ...body,
            },
          };
        },
      }),
      CreateComment: build.mutation<string, CreateCommentForm>({
        query(body) {
          return {
            url: `post/comment/member`,
            method: "POST",
            body: {
              ...body,
            },
          };
        },
      }),
    }),
    overrideExisting: false,
  });
  
  export const {
    useCommentListQuery,
    useCreateCommentMutation,
    useDeleteCommentMutation,
  } = postApi;
  