import {
  EventListRes,
  MonthEventReq,
  UpcomingEventReq,
  UpcomingEventRes,
} from "_interfaces/event.interfaces";
import { Api } from "services/api";

export const eventApi = Api.injectEndpoints({
  endpoints: (build) => ({
    ongoingEvent: build.query<{ data: EventListRes[] }, void>({
      query: () => "event/member/ongoing-event",
      keepUnusedDataFor: 0,
    }),
    detailEvent: build.query<{ data: EventListRes }, string>({
      query: (id) => `event/member/${id}`,
      keepUnusedDataFor: 0,
    }),
    upcomingEvent: build.query<UpcomingEventRes, UpcomingEventReq>({
      query: (param) =>
        `event/member/upcoming-event?page=${param.page}&limit=${param.limit}&search=${param.search}`,
      keepUnusedDataFor: 0,
    }),
    eventByMonth: build.query<{ data: EventListRes[] }, MonthEventReq>({
      query: (param) =>
        `event/member/month-event?month=${param.month}&year=${param.year}`,
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const {
  useOngoingEventQuery,
  useUpcomingEventQuery,
  useDetailEventQuery,
  useEventByMonthQuery,
} = eventApi;
