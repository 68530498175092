import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "store";
import { useUpdateAvatarProfileMutation, useUpdateBannerProfileMutation, useUpdateProfileMutation } from "services/modules/profile";
import { UpdateProfileForm, UpdateProfileReq } from "_interfaces/user.interface";
import { toast } from "react-toastify";
import { uploadFile } from "services/modules/file";


const useUpdateProfileForm = (id?: string) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [updateProfile] = useUpdateProfileMutation();
  const { accessToken } = useAppSelector((state) => state.auth);
  const [updateAvatar] = useUpdateAvatarProfileMutation();
  const [updateBanner] = useUpdateBannerProfileMutation();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Name cannot empty"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setFocus,
    watch,
    reset,
    setValue,
  } = useForm<UpdateProfileForm>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      avatar: { link: "" },
      banner: { link: "" },
    },
  });

  const update = async (data: UpdateProfileForm) => {
    try {
      setIsLoading(true);
      const payload = {
        name: data.name,
        gender: data.gender,
        company_address: data.company_address,
        about: data.about,
        dob: new Date(data.dob).toISOString(),
      };
      
      const payloadAvatar = {
        avatar: data.avatar.link!,
      };

      if (data.avatar.file && data.avatar.file[0]) {
        const file = await uploadFile(accessToken!, data.avatar.file[0] as File);
        payloadAvatar.avatar = file;
      }

      const payloadBanner = {
        banner: data.banner.link!,
      };
      if (data.banner.file && data.banner.file[0]) {
        const file = await uploadFile(accessToken!, data.banner.file[0] as File);
        payloadBanner.banner = file;
      }

      await updateProfile({ id: id!, body: payload }).unwrap();
      await updateAvatar(payloadAvatar).unwrap();
      await updateBanner(payloadBanner).unwrap();

      reset();
      navigate(-1);
      toast.success("Profile successfully updated")
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = handleSubmit(update);

  return {
    handleUpdate,
    register,
    errors,
    setFocus,
    control,
    isLoading,
    watch,
    reset,
    setValue,
    handleSubmit,
    update,
  };
};

export default useUpdateProfileForm;
