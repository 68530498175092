import { RootState } from "store";
import { useSelector } from "react-redux";
import {
  useDeletePostMutation,
  useLikePostMutation,
  useMyPostListQuery,
  usePinPostMutation,
} from "services/modules/post";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "services/errorHandler";
import { toast } from "react-toastify";
import {
  PostList,
  PostListReq,
  ProfilePostList,
} from "_interfaces/post.interface";
import ShareLink from "components/post/ShareLink";
import DeletePopUp from "components/modal/other/Delete";
import PDFViewer from "components/post/PDFViewer";
import PopUpImage from "components/modal/banner/PopUpImage";
import {
  Card,
  Avatar,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import CreatePostPage from "pages/post/create.page";
import moment from "moment";
import {
  Liked,
  MoreOptions,
  Share,
  CommentIcon,
  Like,
  PDF,
  DefaultAvatar,
} from "assets/images";
import UpdatePostPage from "pages/post/update.page";
import React from "react";
import { MdPushPin } from "react-icons/md";
import { useNotifyMeMutation } from "services/notification";
import { NotifyMeReq } from "_interfaces/notification.interface";
import { Loader } from "components/spinner/loader";

interface UpdatePostProps {
  id: string;
}

const PostInfoFriend: React.FC<UpdatePostProps> = ({ id }) => {
  const navigate = useNavigate();
  const [params, setParams] = useState<PostListReq>({
    page: 1,
    limit: 10,
    search: "",
  });

  const { data, isLoading, refetch } = useMyPostListQuery({
    id: id,
    param: params,
  });

  const [selectedPost, setSelectedPost] = useState<string>("");
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isImagePopUp, setIsImagePopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [isDropdownOpen, setIsDropdownOpen] = useState<string | null>(null);

  const [deletePost] = useDeletePostMutation();
  const handleDeletePopUp = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleDeletePost = async (id: string): Promise<void> => {
    try {
      const statusUpdated = { id };
      await deletePost(statusUpdated);
      refetch();
      toast.success("Post successfully deleted");
    } catch (error) {
      errorHandler(error);
    }
  };

  const [likePost] = useLikePostMutation();

  const [postList, setPostList] = useState<ProfilePostList[]>([]);
  const [userName, setUserName] = useState<string | undefined>("");

  useEffect(() => {
    if (data?.data) {
      const updatedPostList = data.data.map((post) => {
        setUserName(post.user?.name);
        const likes = post.isLiked ? post.likes + 1 : post.likes;
        return { ...post, likes };
      });
      setPostList(updatedPostList);
    }
  }, [data]);

  const handleLike = async (postId: string): Promise<void> => {
    try {
      const postToUpdate = postList.find((post) => post.id === postId);
      if (!postToUpdate) {
        throw new Error("Post not found in the list");
      }

      const updatedPostList = postList.map((post) => {
        if (post.id === postId) {
          const likes = post.isLiked ? post.likes - 1 : post.likes + 1;
          return {
            ...post,
            isLiked: !post.isLiked,
            likes,
          };
        }
        return post;
      });

      setPostList(updatedPostList);

      await likePost({ postId });
    } catch (error) {
      errorHandler(error);
    }
  };

  const [isSharePopUp, setIsSharePopupOpen] = useState(false);

  const [url, setUrl] = useState("");

  const [file, setFile] = useState("");
  const [modalPDF, setModalPDF] = useState<boolean>(false);

  const isValidUrl = (url: string): boolean => {
    try {
      if (url) {
        new URL(url);
        return true;
      } else {
        return false;
      }
    } catch (_) {
      return false;
    }
  };

  const loadMorePosts = async () => {
    const newLimit = params.limit + 10;
    setParams((prevParams) => ({
      ...prevParams,
      limit: newLimit,
    }));
    refetch();
  };

  const getPostLikedStatus = (postId: string | undefined): boolean => {
    const post = postList.find((post) => post.id === postId);
    return post ? post.isLiked : false;
  };

  const getPostLikesCount = (postId: string | undefined): number => {
    const post = postList.find((post) => post.id === postId);
    return post ? post.likes : 0;
  };

  const handleNavigateToDetail = (postId: string) => {
    navigate(`/home/post/${postId}`);
  };

  const [pinPost] = usePinPostMutation();
  const handlePinPost = async (postId: string) => {
    await pinPost({ postId });
    toast.success("Post successfully pinned");
    refetch();
  };

  const [notifyMe] = useNotifyMeMutation();

  const handleNotifyMe = async (data: NotifyMeReq) => {
    try {
      await notifyMe(data).unwrap();
      toast.success("Notify me success");
    } catch (error) {
      errorHandler(error);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <div>No data found.</div>;
  }

  return (
    <>
      {data?.pinnedPost && (
        <Card
          placeholder={""}
          className="flex flex-col p-5 my-3 shadow-bottom-right shadow-lg"
        >
          <div className="flex items-center mb-2">
            <MdPushPin />
            <span>Pinned</span>
          </div>
          <div className="flex gap-4 mb-4">
            {data?.pinnedPost.user?.avatar ? (
              <Avatar
                placeholder={""}
                src={data?.pinnedPost.user?.avatar}
                alt="avatar"
                variant="circular"
              />
            ) : (
              <Avatar
                placeholder={""}
                src={DefaultAvatar}
                alt="avatar"
                variant="circular"
              />
            )}
            <div className="flex flex-col">
              <p className="text-lg font-semibold text-black">
                {data?.pinnedPost.by_admin ? "Admin" : userName}
              </p>
              <p className="text-sm font-medium">
                {moment(data?.pinnedPost.created_at).fromNow()}
              </p>
            </div>
            <div className="ml-auto">
              <Menu>
                <MenuHandler>
                  <button
                    className="text-center text-lg text-black border-none"
                    onClick={() => {
                      if (isDropdownOpen === data?.pinnedPost.id) {
                        setIsDropdownOpen(null);
                      } else {
                        setIsDropdownOpen(data?.pinnedPost.id as string);
                      }
                    }}
                  >
                    <img
                      src={MoreOptions}
                      alt="More options icon"
                    />
                  </button>
                </MenuHandler>
                <MenuList placeholder={""}>
                  {data?.pinnedPost.userId === id && (
                    <div>
                      <MenuItem
                        placeholder={""}
                        className="p-0"
                        onClick={() => {
                          setSelectedPost(data?.pinnedPost.id as string);
                          setIsUpdatePopupOpen(true);
                        }}
                      >
                        <label
                          htmlFor="item-1"
                          className="flex cursor-pointer items-center gap-2 p-2 text-black hover:bg-gray-100"
                        >
                          Edit Post
                        </label>
                      </MenuItem>

                      <MenuItem
                        placeholder={""}
                        className="p-0"
                        onClick={() => {
                          handlePinPost(data?.pinnedPost.id as string);
                        }}
                      >
                        <label
                          htmlFor="item-1"
                          className="flex cursor-pointer items-center gap-2 p-2 text-black hover:bg-gray-100"
                        >
                          Pin to Profile
                        </label>
                      </MenuItem>

                      <MenuItem
                        placeholder={""}
                        className="p-0"
                        onClick={() => {
                          setSelectedPost(data?.pinnedPost.id as string);
                          handleDeletePopUp();
                        }}
                      >
                        <label
                          htmlFor="item-1"
                          className="flex cursor-pointer items-center gap-2 p-2 text-red-800 hover:bg-gray-100"
                        >
                          Delete Post
                        </label>
                      </MenuItem>
                    </div>
                  )}

                  {data?.pinnedPost.userId !== id && (
                    <div>
                      <MenuItem
                        placeholder={""}
                        className="p-0"
                      >
                        <label
                          htmlFor="item-1"
                          className="flex cursor-pointer items-center gap-2 p-2 text-red-800 hover:bg-gray-100"
                        >
                          Report
                        </label>
                      </MenuItem>
                      <MenuItem
                        placeholder={""}
                        className="p-0"
                      >
                        <label
                          htmlFor="item-1"
                          className="flex cursor-pointer items-center gap-2 p-2 text-black hover:bg-gray-100"
                          onClick={() => {
                            if (
                              data?.pinnedPost.userId !== id &&
                              data?.pinnedPost.by_admin === false
                            ) {
                              handleNotifyMe({
                                subscriber_id: id as string,
                                publisher_id: data?.pinnedPost.userId as string,
                              });
                            }
                          }}
                        >
                          Notify Me
                        </label>
                      </MenuItem>
                    </div>
                  )}
                </MenuList>
              </Menu>
            </div>
          </div>
          <div className="flex text-sm font-medium mb-4">
            {data?.pinnedPost.text}
          </div>
          {(data?.pinnedPost.images?.length as number) > 0 && (
            <div className="w-full grid grid-cols-2 gap-2">
              {data?.pinnedPost.images.map((el: string, i: number) => {
                if (!isValidUrl(el)) {
                  return null;
                }
                return (
                  <img
                    key={i}
                    src={el}
                    onClick={() => {
                      setSelectedImage(el);
                      setIsImagePopupOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                    className={`rounded-xl object-cover w-full h-[200px] flex flex-col text-center items-center justify-center ${
                      data?.pinnedPost.images?.length === 1 && i === 0
                        ? "col-span-2"
                        : "col-span-1"
                    }`}
                  />
                );
              })}
            </div>
          )}
          <div className="flex items-right">
            {isValidUrl(data?.pinnedPost.file || "") && (
              <button
                onClick={() => {
                  setModalPDF(true);
                  setFile(data?.pinnedPost.file as string);
                }}
                className="flex flex-col items-center hover:bg-transparent border-none text-center ml-4"
              >
                <img
                  src={PDF}
                  alt="pdf"
                  className="w-14 h-14"
                />
                <p className="flex text-xs font-medium">
                  {
                    data.pinnedPost.file?.split("/")[
                      data.pinnedPost.file.split("/").length - 1
                    ]
                  }
                </p>
              </button>
            )}
            {modalPDF && (
              <PDFViewer
                file={file}
                isOpen={modalPDF}
                onClose={() => setModalPDF(false)}
              />
            )}
          </div>
          <div className="flex justify-left mt-4">
            {/* <button
              className="flex items-center gap-1"
              onClick={() => {
                setSelectedPost(data?.pinnedPost.id as string);
                handleLike(data?.pinnedPost.id as string);
              }}
            >
              <img
                className={`transition-transform ${
                  getPostLikedStatus(data?.pinnedPost.id) ? "scale-125" : ""
                }`}
                src={getPostLikedStatus(data?.pinnedPost.id) ? Liked : Like}
                alt={
                  getPostLikedStatus(data?.pinnedPost.id)
                    ? "Liked Icon"
                    : "Like Icon"
                }
              />
              <span>{getPostLikesCount(data?.pinnedPost.id)}</span>
            </button> */}
            <button
              // className="ml-4"
              onClick={() => {
                handleNavigateToDetail(data?.pinnedPost.id);
              }}
            >
              <img
                src={CommentIcon}
                alt="Comment Icon"
              />
            </button>
            <button
              className="ml-4"
              onClick={() => {
                setIsSharePopupOpen(true);
                setUrl(`${window.location.href}/post/${data?.pinnedPost.id}`);
              }}
            >
              <img
                src={Share}
                alt="Share Icon"
              />
            </button>
          </div>
        </Card>
      )}

      {data?.data
        .filter((data) => !data.system)
        .map((data: ProfilePostList, index: number) => (
          <Card
            placeholder={""}
            className="flex flex-col p-5 my-3 shadow-bottom-right shadow-lg"
            key={index}
          >
            <div className="flex gap-4 mb-4">
              {data.user?.avatar ? (
                <Avatar
                  placeholder={""}
                  src={data.user?.avatar}
                  alt="avatar"
                  variant="circular"
                />
              ) : (
                <Avatar
                  placeholder={""}
                  src={DefaultAvatar}
                  alt="avatar"
                  variant="circular"
                />
              )}
              <div className="flex flex-col">
                <p className="text-lg font-semibold text-black">
                  {data?.by_admin ? "Admin" : data?.user?.name}
                </p>
                <p className="text-sm font-medium">
                  {moment(data?.created_at).fromNow()}
                </p>
              </div>
              <div className="ml-auto">
                <Menu>
                  <MenuHandler>
                    <button
                      className="text-center text-lg text-black border-none"
                      onClick={() => {
                        if (isDropdownOpen === data?.id) {
                          setIsDropdownOpen(null);
                        } else {
                          setIsDropdownOpen(data?.id as string);
                        }
                      }}
                    >
                      <img
                        src={MoreOptions}
                        alt="More options icon"
                      />
                    </button>
                  </MenuHandler>
                  <MenuList placeholder={""}>
                    {data?.user?.id === id && (
                      <div>
                        <MenuItem
                          placeholder={""}
                          className="p-0"
                          onClick={() => {
                            setSelectedPost(data?.id as string);
                            setIsUpdatePopupOpen(true);
                          }}
                        >
                          <label
                            htmlFor="item-1"
                            className="flex cursor-pointer items-center gap-2 p-2 text-black hover:bg-gray-100"
                          >
                            Edit Post
                          </label>
                        </MenuItem>

                        <MenuItem
                          placeholder={""}
                          className="p-0"
                          onClick={() => {
                            handlePinPost(data?.id as string);
                          }}
                        >
                          <label
                            htmlFor="item-1"
                            className="flex cursor-pointer items-center gap-2 p-2 text-black hover:bg-gray-100"
                          >
                            Pin to Profile
                          </label>
                        </MenuItem>

                        <MenuItem
                          placeholder={""}
                          className="p-0"
                          onClick={() => {
                            setSelectedPost(data?.id as string);
                            handleDeletePopUp();
                          }}
                        >
                          <label
                            htmlFor="item-1"
                            className="flex cursor-pointer items-center gap-2 p-2 text-red-800 hover:bg-gray-100"
                          >
                            Delete Post
                          </label>
                        </MenuItem>
                      </div>
                    )}

                    {data?.user?.id !== id && (
                      <div>
                        <MenuItem
                          placeholder={""}
                          className="p-0"
                        >
                          <label
                            htmlFor="item-1"
                            className="flex cursor-pointer items-center gap-2 p-2 text-red-800 hover:bg-gray-100"
                          >
                            Report
                          </label>
                        </MenuItem>
                        <MenuItem
                          placeholder={""}
                          className="p-0"
                        >
                          <label
                            htmlFor="item-1"
                            className="flex cursor-pointer items-center gap-2 p-2 text-black hover:bg-gray-100"
                            onClick={() => {
                              if (
                                data?.user?.id !== id &&
                                data?.by_admin === false
                              ) {
                                handleNotifyMe({
                                  subscriber_id: id as string,
                                  publisher_id: data?.user?.id as string,
                                });
                              }
                            }}
                          >
                            Notify Me
                          </label>
                        </MenuItem>
                      </div>
                    )}
                  </MenuList>
                </Menu>
              </div>
            </div>
            <div className="flex text-sm font-medium mb-4">{data?.text}</div>
            {(data?.images?.length as number) > 0 && (
              <div className="w-full grid grid-cols-2 gap-2">
                {data?.images.map((el: string, i: number) => {
                  if (!isValidUrl(el)) {
                    return null;
                  }
                  return (
                    <img
                      key={i}
                      src={el}
                      onClick={() => {
                        setSelectedImage(el);
                        setIsImagePopupOpen(true);
                      }}
                      style={{ cursor: "pointer" }}
                      className={`rounded-xl object-cover w-full h-[200px] flex flex-col text-center items-center justify-center ${
                        data?.images?.length === 1 && i === 0
                          ? "col-span-2"
                          : "col-span-1"
                      }`}
                    />
                  );
                })}
              </div>
            )}
            <div className="flex items-right">
              {isValidUrl(data?.file || "") && (
                <button
                  onClick={() => {
                    setModalPDF(true);
                    setFile(data?.file as string);
                  }}
                  className="flex flex-col items-center hover:bg-transparent border-none text-center ml-4"
                >
                  <img
                    src={PDF}
                    alt="pdf"
                    className="w-14 h-14"
                  />
                  <p className="flex text-xs font-medium">
                    {data.file?.split("/")[data.file.split("/").length - 1]}
                  </p>
                </button>
              )}
              {modalPDF && (
                <PDFViewer
                  file={file}
                  isOpen={modalPDF}
                  onClose={() => setModalPDF(false)}
                />
              )}
            </div>
            <div className="flex justify-left mt-4">
              <button
                className="flex items-center gap-1"
                onClick={() => {
                  setSelectedPost(data?.id as string);
                  handleLike(data?.id as string);
                }}
              >
                <img
                  className={`transition-transform ${
                    getPostLikedStatus(data?.id) ? "scale-125" : ""
                  }`}
                  src={getPostLikedStatus(data?.id) ? Liked : Like}
                  alt={
                    getPostLikedStatus(data?.id) ? "Liked Icon" : "Like Icon"
                  }
                />
                <span>{getPostLikesCount(data?.id)}</span>
              </button>
              <button
                className="ml-4"
                onClick={() => {
                  handleNavigateToDetail(data?.id);
                }}
              >
                <img
                  src={CommentIcon}
                  alt="Comment Icon"
                />
              </button>
              <button
                className="ml-4"
                onClick={() => {
                  setIsSharePopupOpen(true);
                  setUrl(`${window.location.href}/post/${data?.id}`);
                }}
              >
                <img
                  src={Share}
                  alt="Share Icon"
                />
              </button>
            </div>
          </Card>
        ))}

      {data?.data.length !== undefined && data?.data.length >= params.limit && (
        <button
          onClick={loadMorePosts}
          className="mb-4"
        >
          load more
        </button>
      )}

      <ShareLink
        open={isSharePopUp}
        onClose={() => setIsSharePopupOpen(false)}
        url={url}
      />

      <DeletePopUp
        isOpen={isDeletePopupOpen}
        data={"Post"}
        onClose={handleDeletePopUp}
        onEdit={() => {
          handleDeletePopUp();
          void handleDeletePost(selectedPost);
        }}
        menu="Post"
      />

      <UpdatePostPage
        isOpen={isUpdatePopupOpen}
        onClose={() => setIsUpdatePopupOpen(false)}
        id={selectedPost as string}
        success={() => {
          refetch();
        }}
      />

      {selectedImage !== "" && (
        <PopUpImage
          isOpen={isImagePopUp}
          data={selectedImage}
          onClose={() => {
            setIsImagePopupOpen(false);
            setSelectedImage("");
          }}
        />
      )}
    </>
  );
};

export default PostInfoFriend;

