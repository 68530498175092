import { Avatar, Card } from "@material-tailwind/react";
import { Comment, CreateCommentForm } from "_interfaces/comment.interface";
import { DefaultAvatar, DeleteIcon, Send } from "assets/images";
import DeletePopUp from "components/modal/other/Delete";
import DetailPostCard from "components/post/DetailPost";
import { Loader } from "components/spinner/loader";
import useCreateCommentForm from "hooks/comment/UseCreateCommentForm";
import moment from "moment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "services/errorHandler";
import {
  useCommentListQuery,
  useDeleteCommentMutation,
} from "services/modules/comment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import PostHeader from "components/post/Header";
import UpcomingEvent from "components/post/UpcomingEvents";
import { Helmet } from "react-helmet";
import Sidebar from "components/shared/sidebar";
import Container from "layout/container";
import { useGetProfileQuery } from "services/modules/profile";
import { useAppSelector } from "store";

export const detailPostRouteName = "post/:id";
export default function DetailPostPage(): React.ReactElement {
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const { user } = useAppSelector((state) => state.auth);
  const { id } = useParams();
  const [navbarActive, setNavbarActive] = useState<boolean>(false);
  const toggleSidebar = (): void => {
    setNavbarActive((prev) => !prev);
  };

  const { errors, register, handleSubmit, create } = useCreateCommentForm();
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [searchParams, setSearchParams] = useState({
    search: "",
    limit: 10,
    page: 1,
  });
  const [selectedComment, setSelectedComment] = useState<string>("");

  const { data, isLoading, refetch } = useCommentListQuery({
    ...searchParams,
    id: id as string,
  });

  const [deleteComment] = useDeleteCommentMutation();

  const handleDeletePopUp = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleDeleteComment = async (id: string): Promise<void> => {
    try {
      const statusUpdated = { id };
      await deleteComment(statusUpdated);
      refetch();
      toast.success("Comment successfully deleted");
    } catch (error) {
      errorHandler(error);
    }
  };

  const onSubmit = async (data: CreateCommentForm) => {
    try {
      await create(data);
      refetch();
      toast.success("Comment successfully added");
    } catch (error) {
      errorHandler(error);
    }
  };

  const loadMoreComment = async () => {
    const newLimit = searchParams.limit + 10;
    setSearchParams((prevParams) => ({
      ...prevParams,
      limit: newLimit,
    }));

    refetch();
  };

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{t("Spix | Community")}</title>
      </Helmet>
      <div className="block lg:flex lg:flex-row">
        <Sidebar active={navbarActive} toggleSidebar={toggleSidebar} />
        <Container className={`${"w-full lg:w-[60%] lg:relative"}`}>
          <>
            <PostHeader
              className={`${"w-full lg:absolute"}`}
              toggleSidebar={toggleSidebar}
              headerTitle="Comment"
              onSearch={(searchTerm) => {
                setSearchParams((prev) => ({ ...prev, search: searchTerm }));
                refetch();
              }}
              isBack={true}
            />

            <div className="flex flex-col px-4 pt-5">
              <DeletePopUp
                isOpen={isDeletePopupOpen}
                data={"Comment"}
                onClose={handleDeletePopUp}
                onEdit={() => {
                  handleDeletePopUp();
                  void handleDeleteComment(selectedComment);
                }}
                menu="Comment"
              />

              <Card
                placeholder={""}
                className="flex flex-col p-5 shadow-bottom-right shadow-xl"
                style={{ height: "calc(100vh - 120px)" }}
              >
                <div className="overflow-y-auto h-screen no-scrollbar mb-[60px]">
                  <DetailPostCard />

                  {data?.data.map((data: Comment, index: number) => (
                    <div className="my-9" key={index}>
                      <div className="flex items-center justify-between mb-3">
                        <div
                          className="flex items-center gap-4"
                          onClick={() => {
                            if (
                              data?.user?.id !== user?.id &&
                              data?.user?.id !== undefined
                            ) {
                              navigate(`/profile/${data.user?.id}`);
                            } else if (data?.user?.id === user?.id) {
                              navigate(`/profile`);
                            }
                          }}
                        >
                          {data?.user?.avatar ? (
                            <Avatar
                              placeholder={""}
                              src={data?.user?.avatar}
                              alt="avatar"
                              variant="circular"
                              className="cursor-pointer"
                            />
                          ) : (
                            <Avatar
                              placeholder={""}
                              src={DefaultAvatar}
                              alt="avatar"
                              variant="circular"
                              className="cursor-pointer"
                            />
                          )}
                          <div className="flex flex-col">
                            <p className="text-lg font-semibold text-black cursor-pointer">
                              {data?.user ? data?.user.name : "Admin"}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center gap-4">
                          {data?.user?.id === user?.id && (
                            <button
                              onClick={() => {
                                setSelectedComment(data?.id as string);
                                handleDeletePopUp();
                              }}
                            >
                              <img src={DeleteIcon} alt="Delete Icon" />
                            </button>
                          )}
                          <p className="text-sm font-medium">
                            {moment(data?.created_at).fromNow()}
                          </p>
                        </div>
                      </div>
                      <div className="text-sm font-medium bg-[#F7F7F7] p-2 rounded-md">
                        {data?.text}
                      </div>
                    </div>
                  ))}

                  {data?.data.length !== undefined &&
                    data?.data.length >= searchParams.limit && (
                      <div className="flex items-center justify-center">
                        <button className="" onClick={loadMoreComment}>
                          load more
                        </button>
                      </div>
                    )}
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="absolute p-4 bottom-0 left-0 right-0 bg-white">
                    <div className="flex items-center flex-row gap-4">
                      {profile?.data.avatar ? (
                        <Avatar
                          placeholder={""}
                          src={profile?.data.avatar}
                          alt="avatar"
                          variant="circular"
                        />
                      ) : (
                        <Avatar
                          placeholder={""}
                          src={DefaultAvatar}
                          alt="avatar"
                          variant="circular"
                        />
                      )}
                      <input
                        type="text"
                        className="outline-none rounded-md bg-[#F7F7F7] w-full px-4 py-2"
                        value={id}
                        {...register("postId")}
                        hidden
                      />
                      <input
                        type="text"
                        className="outline-none rounded-md bg-[#F7F7F7] w-full px-4 py-2"
                        {...register("text")}
                      />
                      <button type="submit">
                        <img src={Send} alt="Send Icon" />
                      </button>
                    </div>
                    {errors.text && (
                      <p className="text-red-400 italic text-sm text-center">
                        {errors.text.message}
                      </p>
                    )}
                  </div>
                </form>
              </Card>
            </div>
          </>
        </Container>
        <div className="bg-white pt-4 hidden lg:block overflow-y-auto no-scrollbar h-screen">
          <UpcomingEvent />
        </div>
      </div>
    </>
  );
}
