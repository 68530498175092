import { eventRouteName } from "pages/event-calendar";

interface HeaderMenu {
  title: string;
  path: string;
}
const HeaderName: HeaderMenu[] = [
  {
    title: "Home",
    path: "post",
  },
  {
    title: "Event Calendar",
    path: eventRouteName,
  },
  {
    title: "Discussion Forum",
    path: "forum",
  },
  {
    title: "Profile",
    path: "profile",
  },
];

export { HeaderName };
