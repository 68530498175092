import { Card } from "@material-tailwind/react";
import React, { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useUpcomingEventQuery } from "services/modules/event";

export default function UpcomingEvent(): React.ReactElement {
  const navigate = useNavigate();
  const [params, setParams] = React.useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const { data, isLoading, refetch } = useUpcomingEventQuery(params);

  return (
    <div className="mx-4 mt-10">
      <h1 className="text-lg text-black font-bold mb-4">Upcoming Events</h1>
      {data?.data.length === 0 ? (
        <div className="text-center p-5">No upcoming events</div>
      ) : (
        <div>
          {data?.data?.map((event, index) => (
            <Card
              placeholder={""}
              onClick={() => {
                navigate("/event/" + event.id);
              }}
              className={`flex flex-row mb-6 p-5 cursor-pointer ${
                index === 0 ? "bg-san-juan text-white" : "bg-white text-black"
              }`}
              key={index}
            >
              <div
                className={`flex items-center flex-col justify-center w-16 h-16 rounded-full p-5 mr-4 ${
                  index === 0 ? "bg-white text-black" : "bg-san-juan text-white"
                }`}
              >
                <h1 className="text-lg font-semibold block">
                  {moment(event.date).format("DD")}
                </h1>
                <p className="text-sm block">
                  {moment(event.date).format("MMM")}
                </p>
              </div>

              <div className="">
                <h3 className="text-md font-bold mb-2">{event.title}</h3>
                <p
                  className={`text-sm mb-1 font-medium ${
                    index === 0 ? "text-white" : "text-gray-800"
                  }`}
                >
                  {event.location}
                </p>
                <p
                  className={`text-sm font-medium w-40 ${
                    index === 0 ? "text-white" : "text-gray-800"
                  }`}
                >
                  {event.description.length > 40
                    ? event.description.slice(0, 35) + "..."
                    : event.description}
                </p>{" "}
              </div>
            </Card>
          ))}

          <div className="text-center mb-6">
            <button
              onClick={() => {
                navigate("/event");
              }}
            >
              See all event
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
